import * as yup from "yup";

const baseInputs = yup.object().shape({
  title: yup.string().required("Title is required."),
  link: yup
    .string()
    .url("Link must be a valid URL")
    .required("Link is required."),
});

export const createStayUpdatedDto = yup
  .object()
  .shape({
    featured_image: yup.mixed().required("Please upload an image."),
  })
  .concat(baseInputs);

export const editStayUpdatedDto = yup
  .object()
  .shape({
    id: yup.string().required(),
    featured_image: yup.mixed().nullable(),
  })
  .concat(baseInputs);
