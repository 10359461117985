import styled from "@emotion/styled";
import { Box, Card, Grid, Paper, Stack, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { useQuery } from "@tanstack/react-query";
import ErrorMsg from "components/admin/UI/ErrorMessage";
import Loader from "components/UI/Loader";
import React from "react";
import { adminApi } from "services/api";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

const chartSetting = {
  width: 1000,
  height: 400,
};

const Home = () => {
  const { data, isLoading, isError } = useQuery({
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    gcTime: 0,
    queryFn: adminApi.getAdminStats,
    queryKey: ["adminStats"],
  });
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMsg error={"Something went wrong , please try again"} />;
  }
  return (
    <Box>
      <BarChart
        dataset={data.data}
        xAxis={[{ scaleType: "band", dataKey: "table_name", barGapRatio: 0.8 }]}
        series={[{ dataKey: "count", label: "No. of records" }]}
        layout="vertical"
        {...chartSetting}
        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
        borderRadius={10}
        colors={["#3b82f6"]}
      />
      <Typography my={2} variant="h5">
        No of records in the database
      </Typography>

      <Grid container spacing={1}>
        {data?.data.map((item) => (
          <Grid item xs={3} sm={4} md={3} key={item.table_name}>
            <Item>
              <Typography
                variant="overline"
                gutterBottom
                sx={{ display: "block", textTransform: "capitalize" }}
              >
                Total {item.table_name?.replaceAll("_", " ")}
              </Typography>
              <Typography px={2} variant="h3" gutterBottom>
                {item.count}
              </Typography>
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Home;
