import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { companyDto } from "dto";
import { baseCompanyInputs } from "constants/form-inputs";
import { ZenForm } from "components/form";
import toast from "react-hot-toast";
import { showMutationError, updateFormDataHelper } from "utils/common";
import { useMutation } from "@tanstack/react-query";
import queryClient from "config/queryClient";
import { CACHE_KEYS } from "constants/common";
import { adminApi } from "services/api";

function returnDefaultValues(defaultValues) {
  return {
    id: defaultValues.id,
    company_name: defaultValues.company_name ?? "",
    listing_preference: defaultValues.listing_preference ?? "",
    buy_rating: defaultValues.buy_rating ?? 0,
    sell_rating: defaultValues.sell_rating ?? 0,
  };
}

const EditCompany = ({ defaultValues, handleClose }) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: returnDefaultValues(defaultValues),
    resolver: yupResolver(companyDto.editCompanyDto),
  });

  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ id, formData }) =>
      adminApi.editCompanyById({ id, payload: formData }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.COMPANY],
      });
    },
  });

  const submitHandler = async (value) => {
    try {
      const formData = updateFormDataHelper(value, dirtyFields);
      await mutateAsync({
        id: defaultValues.id,
        formData,
      });
      handleClose();
      toast.success("Company updated successfully");
    } catch (error) {
      showMutationError(error);
    }
  };

  return (
    <Box sx={{ m: 4 }}>
      <form noValidate onSubmit={handleSubmit(submitHandler)}>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={baseCompanyInputs}
        />
        <Button
          disabled={isPending}
          type="submit"
          variant="contained"
          color="primary"
        >
          {isPending ? "Updating..." : "Update"}
        </Button>
      </form>
    </Box>
  );
};

export default EditCompany;
