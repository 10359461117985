import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

function MediaCard({ title, content, image }) {
  return (
    <Card sx={{ maxWidth: 345 }}>
      {image ? (
        <CardMedia
          sx={{ height: 100, objectFit: "contain" }}
          image={image}
          title={title}
        />
      ) : (
        <></>
      )}

      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}
const DetailedContentView = ({ data = [] }) => {
  return (
    <Stack direction="row" flexWrap={"wrap"} gap={2} sx={{ my: 1 }}>
      {data.map((item, index) => (
        <MediaCard
          key={index}
          title={item?.title}
          content={item?.content}
          image={item?.image}
        />
      ))}
    </Stack>
  );
};

export default DetailedContentView;
