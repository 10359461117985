import { FormControlLabel, FormHelperText, Switch } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import React from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "@emotion/react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const CustomCheckBoxInput = ({
  control,
  options,
  name,
  label,
  type,
  errorFeedback,
}) => {
  const theme = useTheme();

  let content = <></>;
  switch (type) {
    case "switch":
      content = (
        <FormControl sx={{ mb: 2 }} fullWidth error={Boolean(errorFeedback)}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                    name={name}
                  />
                }
                label={label}
              />
            )}
          />
          {errorFeedback && (
            <FormHelperText sx={{ color: "error.main" }}>
              {errorFeedback.message}
            </FormHelperText>
          )}
        </FormControl>
      );
      break;
    case "multiselect":
      content = (
        <FormControl error={Boolean(errorFeedback)} sx={{ mb: 2 }} fullWidth>
          <InputLabel sx={{ textTransform: "capitalize" }} id={name}>
            {label}
          </InputLabel>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Select
                labelId={`${name}-label`}
                id={name}
                multiple
                value={field.value}
                onChange={({ target: { value } }) => {
                  field.onChange(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                input={<OutlinedInput id={name} label={label} />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {options.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, field.value, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      );

    default:
      break;
  }
  return <>{content}</>;
};

export default CustomCheckBoxInput;
