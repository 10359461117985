import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Chip, Divider } from "@mui/material";
import { ZenForm } from "components/form";
import FieldArrayForm from "components/form/FieldArrayForm";
import {
  IMAGE_DEFAULT_ENTRY,
  TITLE_CONTENT_DEFAULT_ENTRY,
} from "constants/common";
import { companyDataWalletInputs } from "constants/form-inputs";
import { useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";

const HEADER_FORM_KEY = "header";
const IMAGES_FORM_KEY = "main_images";
const PARAGRAPH_FORM_KEY = "main_paragraphs";

const BaseCompanyWalletForm = ({
  companyOptions,
  defaultValues,
  submitHandler,
  title,
  isPending,
  dto,
}) => {
  //hook form
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: yupResolver(dto),
  });

  const {
    fields: h_Fields,
    append: h_Append,
    remove: h_Remove,
  } = useFieldArray({
    control,
    name: HEADER_FORM_KEY,
    rules: {
      minLength: 1,
    },
  });

  const {
    fields: i_Fields,
    append: i_Append,
    remove: i_Remove,
  } = useFieldArray({
    control,
    name: IMAGES_FORM_KEY,
    rules: {
      minLength: 1,
    },
  });

  const {
    fields: p_Fields,
    append: p_Append,
    remove: p_Remove,
  } = useFieldArray({
    control,
    name: PARAGRAPH_FORM_KEY,
    rules: {
      minLength: 1,
    },
  });

  const INPUTS = useMemo(() => {
    return companyDataWalletInputs({ companyOptions });
  }, [companyOptions]);

  return (
    <Box sx={{ m: 4 }}>
      <form noValidate onSubmit={handleSubmit(submitHandler)}>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={INPUTS.common}
        />
        <Divider sx={{ mb: 2 }} textAlign="left">
          <Chip label="Meta" color="primary" size="large" />
        </Divider>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={INPUTS.meta}
        />
        <Divider sx={{ mb: 2 }} textAlign="left">
          <Chip label="Header" color="primary" size="large" />
        </Divider>
        <FieldArrayForm
          {...{
            append: h_Append,
            remove: h_Remove,
            fields: h_Fields,
            control,
            errors,
            defaultEntry: TITLE_CONTENT_DEFAULT_ENTRY,
            formKey: HEADER_FORM_KEY,
            arrInputs: INPUTS.header,
            direction: "vertical",
            formLabel: "Header",
          }}
        />
        <Divider sx={{ mb: 2 }} textAlign="left">
          <Chip label="Main Image" color="primary" size="large" />
        </Divider>
        <FieldArrayForm
          {...{
            append: i_Append,
            remove: i_Remove,
            fields: i_Fields,
            control,
            errors,
            defaultEntry: IMAGE_DEFAULT_ENTRY,
            formKey: IMAGES_FORM_KEY,
            arrInputs: INPUTS.main_images,
            direction: "vertical",
            formLabel: "Main Image",
          }}
        />
        <Divider sx={{ mb: 2 }} textAlign="left">
          <Chip label="Main Paragraph" color="primary" size="large" />
        </Divider>
        <FieldArrayForm
          {...{
            append: p_Append,
            remove: p_Remove,
            fields: p_Fields,
            control,
            errors,
            defaultEntry: TITLE_CONTENT_DEFAULT_ENTRY,
            formKey: PARAGRAPH_FORM_KEY,
            arrInputs: INPUTS.main_paragraphs,
            direction: "vertical",
            formLabel: "Main Paragraph",
          }}
        />
        <Button
          disabled={isPending}
          my={4}
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
        >
          {isPending ? "Saving..." : title}
        </Button>
      </form>
    </Box>
  );
};

export default BaseCompanyWalletForm;
