import { Avatar, Box } from "@mui/material";
import React from "react";

const ThumbnailImage = ({ alt, src, icon, size = 40 }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Avatar
        variant="square"
        alt={alt}
        src={src}
        sx={{ width: size, height: size, bgcolor: "primary.main" }}
      >
        {icon ?? alt}
      </Avatar>
    </Box>
  );
};

export default ThumbnailImage;
