import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import withPaginatedQuery from "components/HOC/withPaginatedQuery";
import ZenDataGrid from "components/UI/ZenGrid";
import { CACHE_KEYS } from "constants/common";
import { useMemo, useState } from "react";
import { adminApi } from "services/api";
import { Link } from "react-router-dom";
import BooleanChip from "components/admin/UI/BooleanChip";
import { BaseDrawer } from "components/UI/Base";
import EditUser from "./EditUser";
import { Edit } from "@mui/icons-material";
import HeaderRenderer from "components/admin/UI/InfoTooltip";

const ManageUsers = ({
  paginationModel,
  setPaginationModel,
  data,
  isLoading,
  sortModel,
  handleSearch,
  search,
  setSortModel,
}) => {
  //state
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  //column definition
  const columns = useMemo(() => {
    return [
      {
        field: "id",
        headerName: "#",
        headerAlign: "center",
        align: "center",
      },

      {
        field: "email",
        crmKey: "emailaddress1",
        headerName: "Email",
        minWidth: 250,
        renderHeader:HeaderRenderer,
        renderCell: (params) => {
          if (!params.row.email) return <></>;
          return (
            <Box>
              <Button
                variant="text"
                size="small"
                target="_blank"
                LinkComponent={Link}
                to={`mailto:${params.row.email}`}
              >
                {params.row.email}
              </Button>
            </Box>
          );
        },
      },
      {
        field: "fullname",
        headerName: "Full Name",
        minWidth: 250,
        crmKey:"accountname",
        renderHeader:HeaderRenderer
      },
      {
        minWidth: 150,
        field: "usertype",
        headerName: "User Type",
        crmKey:"pcfsystemfield88",
        renderHeader:HeaderRenderer
      },
      {
        minWidth: 150,
        field: "status",
        headerName: "Status",
        crmKey:"status",
        renderHeader:HeaderRenderer,
      },
      {
        minWidth: 150,
        field: "phone",
        headerName: "Phone",
        crmKey:"telephone1",
        renderHeader:HeaderRenderer,
      },
      {
        field: "newsletter",
        headerName: "Newsletter",
        crmKey:"pcfsystemfield721",
        renderHeader:HeaderRenderer,
        renderCell: (params) => {
          return <BooleanChip value={params.row?.newsletter} />;
        },
      },
      {
        field: "actions",
        type: "actions",
        sortable: false,
        headerName: "Actions",
        getActions(params) {
          return [
            <IconButton
              key="edit-btn"
              onClick={() => {
                setSelectedRow(params.row);
                setOpenEdit(true);
              }}
            >
              <Edit />
            </IconButton>,
          ];
        },
      },
    ];
  }, []);

  let content = <></>;
  if (isLoading) {
    content = (
      <Box my={4} textAlign="center">
        <CircularProgress />;
      </Box>
    );
  }

  if (data) {
    content = (
      <ZenDataGrid
        data={data}
        columns={columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sortModel={sortModel}
        setSortModel={setSortModel}
      />
    );
  }
  return (
    <>
      <Card elevation={3} sx={{ maxWidth: "lg", borderRadius: 2 }}>
        <CardHeader
          title="All Users"
          action={
            <Stack direction="row" spacing={2}>
              <TextField
                value={search}
                onChange={handleSearch}
                sx={{ maxWidth: 150 }}
                size="small"
                variant="outlined"
                placeholder="Search"
              />
            </Stack>
          }
        />
        <Box sx={{ height: 600 }}>{content}</Box>
      </Card>
      {selectedRow ? (
        <>
          <BaseDrawer
            title={"Update User Status"}
            open={openEdit}
            toggleDrawer={() => setOpenEdit(false)}
          >
            <EditUser
              key={selectedRow.id}
              handleClose={() => setOpenEdit(false)}
              defaultValues={selectedRow}
            />
          </BaseDrawer>
        </>
      ) : null}
    </>
  );
};

export default withPaginatedQuery(ManageUsers, {
  queryKey: [CACHE_KEYS.PAGINATED_USERS],
  queryFn: adminApi.getAllPaginatedUsers,
});
