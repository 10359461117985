import { Typography } from "@mui/material";
import DetailedContentView from "components/admin/details/DetailedContentView";
import ImageGallery from "components/admin/details/ImageGallery";
import ObjectView from "components/admin/details/ObjectView";
import DividerChip from "components/admin/UI/DividerChip";
import React, { useMemo } from "react";

const ViewDetails = ({ stockDetails }) => {
  return (
    <>
      <ObjectView title="Meta" data={stockDetails?.meta || {}} />
      <ObjectView
        title="Stocks Price"
        data={stockDetails?.steps?.stocks?.price || {}}
      />
      <ObjectView
        title="Stocks Range"
        data={stockDetails?.steps?.stocks?.range || {}}
      />
      <DividerChip>Header</DividerChip>
      <DetailedContentView data={stockDetails?.header?.items} />
      {/* Tab A */}
      <DividerChip>Tab A</DividerChip>
      <Typography variant="h6">{stockDetails?.taba?.title}</Typography>
      <ImageGallery imageUrls={stockDetails?.taba?.images ?? []} />
      <DetailedContentView data={stockDetails?.taba?.content} />
      {/* Tab B */}
      <DividerChip>Tab B</DividerChip>
      <Typography variant="h6">{stockDetails?.tabb?.title}</Typography>
      <DetailedContentView data={stockDetails?.tabb?.content} />
      {/* Tab C */}
      <DividerChip>Tab C</DividerChip>
      <Typography variant="h6">{stockDetails?.tabc?.title}</Typography>
      <ImageGallery imageUrls={stockDetails?.tabc?.images ?? []} />
      <DetailedContentView data={stockDetails?.tabc?.content} />
      {/* Tab D */}
      <DividerChip>Tab D</DividerChip>
      <Typography variant="h6">{stockDetails?.tabd?.title}</Typography>
      <DetailedContentView data={stockDetails?.tabd?.content} />
    </>
  );
};

export default ViewDetails;
