import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { walletDto } from "dto";
import { walletBuyInputs } from "constants/form-inputs";
import { ZenForm } from "components/form";
import { showMutationError, updateJsonFormHelper } from "utils/common";
import { useMutation } from "@tanstack/react-query";
import { adminApi } from "services/api";
import queryClient from "config/queryClient";
import { CACHE_KEYS } from "constants/common";
import toast from "react-hot-toast";

function returnDefaultValues({ defaultValues, companyOptions, userOptions }) {
  return {
    id: defaultValues.id,
    number_of_stocks: defaultValues?.number_of_stocks ?? 0,
    price_per_stock: defaultValues?.price_per_stock ?? 0,
    steps: defaultValues?.steps ?? 0,
    progress_text: defaultValues?.progress_text ?? 0,
    company_id: defaultValues.company_id
      ? defaultValues.company_id
      : companyOptions?.length
      ? companyOptions[0].id
      : "",
    user_id: defaultValues.user_id
      ? defaultValues.user_id
      : userOptions?.length
      ? userOptions[0].id
      : "",
  };
}

const EditWalletBuy = ({
  handleClose,
  defaultValues,
  companyOptions,
  userOptions,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: returnDefaultValues({
      defaultValues,
      companyOptions,
      userOptions,
    }),
    resolver: yupResolver(walletDto.editWalletBuy),
  });

  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ id, payload }) =>
      adminApi.updateWalletBuyById({ id, payload }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.WALLET_BUY],
      });
    },
  });
  const submitHandler = async (value) => {
    try {
      const payload = updateJsonFormHelper(value, dirtyFields);
      await mutateAsync({
        id: defaultValues.id,
        payload: payload,
      });
      handleClose();
      toast.success("Wallet Buy updated successfully");
      reset();
    } catch (error) {
      showMutationError(error);
    }
  };

  return (
    <Box sx={{ m: 4 }}>
      <form noValidate onSubmit={handleSubmit(submitHandler)}>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={walletBuyInputs({ companyOptions, userOptions })}
        />
        <Button
          disabled={isPending}
          type="submit"
          variant="contained"
          color="primary"
        >
          {isPending ? "Updating..." : "Update"}
        </Button>
      </form>
    </Box>
  );
};

export default EditWalletBuy;
