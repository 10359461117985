import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import withPaginatedQuery from "components/HOC/withPaginatedQuery";
import { CACHE_KEYS, USER_THUMBNAIL_COLUMN } from "constants/common";
import { useMemo } from "react";
import { adminApi } from "services/api";

import ZenDataGrid from "components/UI/ZenGrid";
import { formatCurrency } from "utils/common";
import BooleanChip from "components/admin/UI/BooleanChip";

const MangeRegistrationSurveys = ({
  paginationModel,
  setPaginationModel,
  data,
  isLoading,
  sortModel,
  handleSearch,
  search,
  setSortModel,
}) => {
  const columns = useMemo(() => {
    return [
      {
        field: "survey_id",
        headerName: "#",
        headerAlign: "center",
        align: "center",
      },
      ...USER_THUMBNAIL_COLUMN,

      {
        minWidth: 200,
        field: "user_type",
        headerName: "User Type",
      },
      {
        field: "company_name",
        headerName: "Company Name",
      },
      {
        field: "shares_or_options",
        headerName: "Shares/Options",
      },
      {
        field: "target_price",
        headerName: "Target Price",
        valueGetter: (v) => formatCurrency(v),
      },
      {
        field: "amount_of_shares_options",
        headerName: "Amount of Shares/Options",
        valueGetter: (v) => formatCurrency(v),
      },
      {
        field: "investment_price",
        headerName: "Investment Price",
        valueGetter: (v) => formatCurrency(v),
      },
      {
        field: "investment_preferences",
        headerName: "Investment Preferences",
      },
      {
        field: "investment_period",
        headerName: "Investment Period",
      },
      {
        field: "accredited_investor",
        headerName: "Accredited Investor",
        renderCell: (params) => {
          return <BooleanChip value={params?.row?.accredited_investor} />;
        },
      },
      {
        field: "liquid_asset_one",
        headerName: "Liquid Asset",
        renderCell: (params) => {
          return <BooleanChip value={params?.row?.liquid_asset_one} />;
        },
      },
      {
        field: "annual_income",
        headerName: "Annual Income",
        renderCell: (params) => {
          return <BooleanChip value={params?.row?.annual_income} />;
        },
      },
      {
        field: "liquid_asset_two",
        headerName: "Liquid Asset 2",
        renderCell: (params) => {
          return <BooleanChip value={params?.row?.liquid_asset_two} />;
        },
      },
      {
        field: "corporation_with_equity",
        headerName: "Corporation with Equity",
        renderCell: (params) => {
          return <BooleanChip value={params?.row?.corporation_with_equity} />;
        },
      },
    ];
  }, []);

  let content = <></>;
  if (isLoading) {
    content = (
      <Box my={4} textAlign="center">
        <CircularProgress />;
      </Box>
    );
  }

  if (data) {
    content = (
      <ZenDataGrid
        getRowId={(row) => row.survey_id}
        data={data}
        columns={columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sortModel={sortModel}
        setSortModel={setSortModel}
      />
    );
  }

  return (
    <>
      <Card elevation={3} sx={{ maxWidth: "lg", borderRadius: 2 }}>
        <CardHeader
          title="User Registration Surveys"
          action={
            <Stack direction="row" spacing={2}>
              <TextField
                value={search}
                onChange={handleSearch}
                sx={{ maxWidth: 200 }}
                size="small"
                variant="outlined"
                placeholder="Search"
              />
            </Stack>
          }
        />
        <Box sx={{ height: 600 }}>{content}</Box>
      </Card>
    </>
  );
};

export default withPaginatedQuery(MangeRegistrationSurveys, {
  queryKey: [CACHE_KEYS.SURVEY],
  queryFn: adminApi.getAllSurveys,
});
