import React from "react";
import Grid from "@mui/material/Grid";
import FormInput from "./FormInput";
import DropFileInput from "./DropFileInput";
import CustomOptionsInput from "./OptionsInput";
import CustomCheckBoxInput from "./CheckBoxInput";

const CustomForm = ({ inputs, control, errors, direction }) => {
  return (
    <Grid container columnSpacing={0} rowSpacing={0} mb={1} maxWidth="md">
      {inputs.map((input) => {
        let InputComponent;

        if (
          input.type === "text" ||
          input.type === "email" ||
          input.type === "number" ||
          input.type === "tel"
        ) {
          InputComponent = (
            <FormInput
              {...input}
              control={control}
              errorFeedback={errors[input.name]}
            />
          );
        } else if (input.type === "switch" || input.type === "multiselect") {
          InputComponent = (
            <CustomCheckBoxInput
              control={control}
              {...input}
              errorFeedback={errors[input.name]}
            />
          );
        } else if (input.type === "file") {
          InputComponent = (
            <DropFileInput
              control={control}
              {...input}
              errorFeedback={errors[input.name]}
            />
          );
        } else {
          InputComponent = (
            <CustomOptionsInput
              control={control}
              {...input}
              errorFeedback={errors[input.name]}
            />
          );
        }

        return (
          <Grid
            key={input.name}
            item
            sm={direction === "vertical" ? 12 : 6}
            sx={{ width: "100%" }}
          >
            {InputComponent}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CustomForm;
