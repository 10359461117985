import * as yup from "yup";

export const titleContentFormSchema = yup
  .object()
  .shape({
    title: yup.string().required("Title is required."),
    content: yup.string().required("Content is required."),
  })
  .required();

export const imageFormSchema = yup
  .object()
  .shape({
    image: yup.mixed().required("Please upload an image."),
  })
  .required();
