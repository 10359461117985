import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Logo from "images/valoo-logo.svg";
import InfoIcon from '@mui/icons-material/Info';

import { Link, NavLink, Outlet } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import {
  Assignment,
  Business,
  Dashboard,
  Inventory,
  LogoutTwoTone,
  People,
  Pool,
  Sell,
  ShowChart,
  Update,
  Wallet,
} from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationGuard } from "components/providers/AuthGuard";
import { useAuth } from "hooks/useAuth";

const drawerWidth = 280;

const dashboardRoutes = [
  {
    label: "Dashboard",
    path: "/admin",
    Icon: Dashboard,
  },
  {
    label: "Users",
    path: "/admin/manage-users",
    Icon: People,
  },
  {
    label: "Companies",
    path: "/admin/manage-companies",
    Icon: Business,
  },
  {
    label: "Company Stocks",
    path: "/admin/manage-company-stocks",
    Icon: ShowChart,
  },
  {
    label: "Company Wallet",
    path: "/admin/manage-company-wallet",
    Icon: Wallet,
  },
  {
    label: "NewsLetters",
    path: "/admin/manage-newsletters",
    Icon: MailIcon,
  },
  {
    label: "ShareHolder Updates",
    path: "/admin/manage-shareholders",
    Icon: InboxIcon,
  },
  {
    label: "Stay Updated",
    path: "/admin/manage-stay-updated",
    Icon: Update,
  },
  {
    label: "User CompanyNDA",
    path: "/admin/user-company-nda",
    Icon: Assignment,
  },
  {
    label: "Registration Survey",
    path: "/admin/registration-survey",
    Icon: Pool,
  },
  {
    label: "User Wallet Buy",
    path: "/admin/wallet/buy",
    Icon: Inventory,
  },
  {
    label: "User Wallet Sell",
    path: "/admin/wallet/sell",
    Icon: Sell,
  },
  {
    label: "Field Details",
    path: "/admin/info",
    Icon: InfoIcon,
  },
];

const UserMenu = ({ user }) => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ color: "#001E36" }}
      gap={1}
      direction="row"
    >
      <Avatar sx={{ backgroundColor: "primary.main" }} alt="John">
        {useAuth0().user?.email?.charAt(0)}
      </Avatar>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography py={0} my={0} variant="subtitle1">
          Admin
        </Typography>
        <Typography color={"gray"} py={0} my={0} variant="caption">
          {user?.email}
        </Typography>
      </Box>
    </Stack>
  );
};

function AdminDashboardLayout() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const { user } = useAuth0();
  const { logout, logoutLoading } = useAuth();

  const handleLogout = async () => {
    await logout();
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        py={2}
        component={Link}
        to="/admin"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={Logo} alt="logo" />
      </Box>
      <Divider />
      <List sx={{ height: "80%", overflowY: "auto" }}>
        {dashboardRoutes.map((route) => (
          <ListItem sx={{ py: 0 }} key={route.path}>
            <ListItemButton
              sx={{
                mb:0.3,
                borderRadius: "10px",
                border:".5px solid transparent",
                "&:hover": {
                  color: "#001E36",
                  "& svg": {
                    fill: "#001E36",
                  },
                },
                "&.active": {
                  backgroundColor: "#EEF8FF",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
                  color: "#001E36",
                  "& svg": {
                    fill: "#001E36",
                  },
                },
              }}
              LinkComponent={NavLink}
              to={route.path}
              end
            >
              <ListItemIcon sx={{ minWidth: "40px" }}>
                <route.Icon />
              </ListItemIcon>
              <ListItemText primary={route.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemButton disabled={logoutLoading} onClick={handleLogout}>
            <ListItemIcon sx={{ minWidth: "40px" }}>
              <LogoutTwoTone />
            </ListItemIcon>
            <ListItemText
              primary={logoutLoading ? "Logging out..." : "Logout"}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          <IconButton
            color="#001E36"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}></Box>
          <UserMenu user={user} />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

const Protected = () => {
  return <AuthenticationGuard component={AdminDashboardLayout} />;
};

export default Protected;
