import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../UI/Loader";
import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { apiInstance } from "../../services/api.service";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import { ADMIN_ROLE, rolePrefix } from "constants/common";

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <>
        <Loader />
      </>
    ),
  });

  return <Component />;
};

const BaseLayout = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, user } =
    useAuth0();
  const { updateUserAttributes, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // Use a ref to track if the user creation process has been initiated
  const isUserCreationInitiated = useRef(false);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const createOrGetUser = async () => {
      if (isUserCreationInitiated.current || loading) return; // Prevent double calls
      isUserCreationInitiated.current = true; // Mark the initiation

      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        if (!token) return;

        localStorage.setItem("token", token);
        apiInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;

        const result = await apiInstance.post(
          "/api/auth/upsert-user",
          {
            email: user?.email,
          },
          { signal }
        );

        const fetchedUser = result.data;
        updateUserAttributes(fetchedUser);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.log("Error upserting user:", error);
          await logout();
        }
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated && !isLoading && user) {
      //redirect to if he is admin
      const roles = user?.[rolePrefix];
      if (roles.includes(ADMIN_ROLE)) {
        navigate("/admin", { replace: true });
        return;
      }
      createOrGetUser();
    }

    return () => {
      controller.abort(); // Cleanup: abort on unmount or if the effect re-runs
    };
  }, [isAuthenticated, isLoading]);

  if (isLoading || loading) return <Loader />;

  return <Outlet />;
};

export const ProtectedLayout = () => {
  return <AuthenticationGuard component={BaseLayout}></AuthenticationGuard>;
};
