import { Box, Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          sx={{
            width: "100vw",
            maxWidth: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box mx={9}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              We encountered an unexpected issue on our side. Our team is
              investigating it and will have things running smoothly soon. Thank
              you for your patience
              <Box sx={{ textAlign: "right" }}>
                <Button onClick={() => window.location.reload()} variant="text">
                  Refresh page
                </Button>
              </Box>
            </Alert>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
