import UsersStatusEnum from "constants/UsersStatusEnum";
import * as yup from "yup";

export const editUserDto = yup.object().shape({
  id: yup.number().required("Id is required."),
  status: yup
    .string()
    .oneOf(Object.values(UsersStatusEnum))
    .required("Status is required."),
});
