import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

const DividerChip = ({ children }) => {
  return (
    <Divider sx={{ mb: 2 }} textAlign="left">
      <Chip label={children} color="primary" size="large" />
    </Divider>
  );
};

export default DividerChip;
