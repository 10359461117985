import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import withPaginatedQuery from "components/HOC/withPaginatedQuery";
import { BaseDrawer, BaseModal } from "components/UI/Base";
import ZenDataGrid from "components/UI/ZenGrid";
import { CACHE_KEYS, MODAL_TYPE, MODEL_INITIAL_STATE } from "constants/common";
import React, { useCallback, useMemo, useState } from "react";
import { adminApi } from "services/api";
import CompanyAdd from "./CompanyAdd";
import CompanyEdit from "./CompanyEdit";
import { useMutation } from "@tanstack/react-query";
import { showMutationError } from "utils/common";
import queryClient from "config/queryClient";
import DescriptionIcon from "@mui/icons-material/Description";
import { Link } from "react-router-dom";
import ThumbnailImage from "components/admin/UI/ThumbnailImage";
import toast from "react-hot-toast";
import HeaderRenderer from "components/admin/UI/InfoTooltip";

const ManageCompanies = ({
  paginationModel,
  setPaginationModel,
  data,
  isLoading,
  sortModel,
  handleSearch,
  search,
  setSortModel,
}) => {
  //local state
  const [modalState, setModalState] = useState(MODEL_INITIAL_STATE);
  const [selectedRow, setSelectedRow] = useState(null);

  //api calls
  const { isPending, mutateAsync } = useMutation({
    mutationFn: () => adminApi.deleteCompanyById({ id: selectedRow.id }),
    onSuccess: () => {
      toast("Company deleted successfully");
      queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.COMPANY] });
    },
  });

  const deleteHandler = async () => {
    try {
      await mutateAsync();
      setModalState(MODEL_INITIAL_STATE);
    } catch (error) {
      showMutationError(error);
    }
  };

  //event handlers
  const modalStateHandler = useCallback(
    (key, val) =>
      setModalState((prev) => ({
        ...prev,
        [key]: val,
      })),
    []
  );

  //get signed url

  //column definition
  const columns = useMemo(() => {
    return [
      {
        flex: 0.2,
        field: "id",
        headerName: "#",
        headerAlign: "center",
        align: "center",
      },
      {
        flex: 0.2,
        field: "image_url",
        headerName: "Image",
        sortable: false,
        renderCell: (params) => {
          return (
            <ThumbnailImage
              src={params.row.image_url}
              alt={params.row.company_name?.charAt(0)}
            />
          );
        },
      },
      {
        field: "company_name",
        headerName: "Company Name",
        minWidth:300,
        crmKey:"pcfsystemfield108",
        renderHeader:HeaderRenderer,
      },
      {
        flex: 0.5,
        field: "listing_preference",
        headerName: "Listing Preference",
        sortable: false,
        crmKey:"listing_preference",
        renderHeader:HeaderRenderer,
      },
      {
        flex: 0.2,
        field: "buy_rating",
        headerName: "Buy Rating",
        crmKey:"rating",
        renderHeader:HeaderRenderer,
      },
      {
        flex: 0.2,
        field: "sell_rating",
        headerName: "Sell Rating",
        crmKey:"rating",
        renderHeader:HeaderRenderer,
      },
      {
        flex: 0.3,
        field: "docusend",
        headerName: "DocuSend",
        sortable: false,
        crmKey:"docusend",
        renderHeader:HeaderRenderer,

        renderCell: (params) => {
          if (!params.row.docusend) return <></>;
          return (
            <Box>
              <IconButton
                target="_blank"
                LinkComponent={Link}
                to={params.row.docusend}
              >
                <DescriptionIcon color={"error"} />
              </IconButton>
            </Box>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        sortable: false,
        headerName: "Actions",
        getActions(params) {
          return [
            <IconButton
              key="edit-btn"
              onClick={() => {
                setSelectedRow(params.row);
                modalStateHandler(MODAL_TYPE.EDIT, true);
              }}
            >
              <Edit />
            </IconButton>,
            <IconButton
              key="delete-btn"
              onClick={() => {
                setSelectedRow(params.row);
                modalStateHandler(MODAL_TYPE.DELETE, true);
              }}
            >
              <Delete />
            </IconButton>,
          ];
        },
      },
    ];
  }, []);

  let content = <></>;
  if (isLoading) {
    content = (
      <Box my={4} textAlign="center">
        <CircularProgress />;
      </Box>
    );
  }

  if (data) {
    content = (
      <ZenDataGrid
        data={data}
        columns={columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sortModel={sortModel}
        setSortModel={setSortModel}
      />
    );
  }
  return (
    <>
      <Card elevation={3} sx={{ maxWidth: "lg", borderRadius: 2 }}>
        <CardHeader
          title="Companies"
          action={
            <Stack direction="row" spacing={2}>
              <TextField
                value={search}
                onChange={handleSearch}
                sx={{ maxWidth: 200 }}
                size="small"
                variant="outlined"
                placeholder="Search"
              />
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  modalStateHandler(MODAL_TYPE.CREATE, true);
                }}
              >
                Add Company
              </Button>
            </Stack>
          }
        />
        <Box sx={{ height: 600 }}>{content}</Box>
      </Card>
      <>
        <BaseDrawer
          open={modalState[MODAL_TYPE.CREATE]}
          title={"Add Company"}
          toggleDrawer={() => modalStateHandler(MODAL_TYPE.CREATE, false)}
        >
          <CompanyAdd
            handleClose={() => modalStateHandler(MODAL_TYPE.CREATE, false)}
          />
        </BaseDrawer>
        {selectedRow ? (
          <>
            <BaseDrawer
              title={"Edit Company"}
              open={modalState[MODAL_TYPE.EDIT]}
              toggleDrawer={() => modalStateHandler(MODAL_TYPE.EDIT, false)}
            >
              <CompanyEdit
                key={selectedRow.id}
                handleClose={() => modalStateHandler(MODAL_TYPE.EDIT, false)}
                defaultValues={selectedRow}
              />
            </BaseDrawer>
            <BaseModal
              handleClick={deleteHandler}
              open={modalState[MODAL_TYPE.DELETE]}
              handleClose={() => modalStateHandler(MODAL_TYPE.DELETE, false)}
              loading={isPending}
              title="Are you sure you want to delete this company?"
            />
          </>
        ) : null}
      </>
    </>
  );
};

export default withPaginatedQuery(ManageCompanies, {
  queryKey: [CACHE_KEYS.COMPANY],
  queryFn: adminApi.getAllCompanies,
});
