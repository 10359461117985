import React, { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import ErrorMsg from "components/admin/UI/ErrorMessage";
import { PAGINATION_OPTIONS } from "constants/common";
import useDebounce from "hooks/useDebounce";

const withPaginatedQuery = (WrappedComponent, options) => {
  const WithPaginatedQuery = (props) => {
    // pagination
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: PAGINATION_OPTIONS[0],
    });
    //filter
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 500);
    //sort
    const [sortModel, setSortModel] = useState([
      {
        field: "id",
        sort: "desc",
      },
    ]);

    const { data, error, isLoading } = useQuery({
      queryKey: [
        ...options.queryKey,
        paginationModel.page + 1,
        paginationModel.pageSize,
        debouncedSearch,
        sortModel?.[0]?.field,
        sortModel?.[0]?.sort,
      ],
      queryFn: () =>
        options.queryFn({
          page: paginationModel.page + 1,
          limit: paginationModel.pageSize,
          search: debouncedSearch,
          sortBy: sortModel[0]?.field,
          sortOrder: sortModel[0]?.sort,
        }),
      keepPreviousData: true,
    });

    const handleSearch = useCallback((event) => {
      setSearch(event.target.value);
    }, []);

    if (error) {
      return <ErrorMsg error={error} />;
    }
    return (
      <WrappedComponent
        {...props}
        isLoading={isLoading}
        data={data?.data}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sortModel={sortModel}
        setSortModel={setSortModel}
        handleSearch={handleSearch}
        search={search}
      />
    );
  };

  WithPaginatedQuery.displayName = `WithPaginatedQuery(${getDisplayName(
    WrappedComponent
  )})`;

  return WithPaginatedQuery;
};

// Helper function to get the display name of a component
const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
};

export default withPaginatedQuery;
