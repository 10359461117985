import { Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";
import List from "@mui/material/List";
import { FormHelperText } from "@mui/material";
import { SizeCalculation } from "utils/common";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUploadOutlined";
// Styled component for the upload image inside the dropzone area
const Img = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    marginRight: theme.spacing(10),
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    width: 250,
  },
}));

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(4),
  },
}));

const DropzoneFileInput = ({
  name,
  multiple,
  control,
  dropConfig,
  label,
  errorFeedback,
}) => {
  return (
    <Controller
      render={({ field: { onChange } }) => (
        <>
          <Dropzone
            multiple={multiple}
            dropConfig={dropConfig}
            onChange={onChange}
            label={label}
          />
          {errorFeedback && (
            <FormHelperText sx={{ color: "error.main" }}>
              {errorFeedback.message}
            </FormHelperText>
          )}
        </>
      )}
      name={name}
      control={control}
    />
  );
};

export default React.memo(DropzoneFileInput);

function FileList({ files, setFiles }) {
  return (
    <>
      {files.map((file) => (
        <ListItem key={file.name}>
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "column", "row"],
              alignItems: "center",
              gap: 2,
            }}
          >
            {/* <InsertDriveFile /> */}
            <InsertDriveFileIcon />
            <div>
              <Typography>{file.name?.slice(0, 20) + "..."}</Typography>
              <Typography variant="body2">
                {SizeCalculation(file.size)}
              </Typography>
            </div>
          </Box>
          <IconButton
            onClick={() => setFiles([])}
            sx={{
              marginBottom: 5,
              marginLeft: 2,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </ListItem>
      ))}
    </>
  );
}

const Dropzone = ({ multiple, label, onChange, dropConfig }) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    ...dropConfig,
    onDropAccepted(acceptedFiles) {
      setFiles(acceptedFiles);
    },
    onDropRejected: (rejections) => {
      let error = "Invalid File is not allowed.";
      if (rejections.length && rejections[0].errors.length) {
        const resson = rejections[0].errors[0].code;
        if (resson === "file-too-large") {
          error = "File size exceeds the limit of 10 MB.";
        }
      }
      toast.error(error, {
        duration: 3000,
      });
    },
  });

  useEffect(() => {
    onChange(multiple ? files : files[0] || null);
  }, [files, onChange, multiple]);

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Box
          p={2}
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 2,
            border: `2px dashed ${isDragActive ? "#1d4ed8" : "#e5e7eb"}`,
          }}
        >
          <CloudUploadIcon sx={{ fontSize: 50, color: "#d1d5db" }} />
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Drop "
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: 600,
                color: "#001E36",
                marginInline: "4px",
              }}
            >
              {label}
            </span>
            " or Click, {dropConfig?.hint}
          </Typography>
        </Box>
      </div>
      {files.length ? (
        <List>
          <FileList files={files} setFiles={setFiles} />
        </List>
      ) : null}
    </>
  );
};
