import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

const DetailedImage = ({ image, title, subtitle }) => {
  return (
    <ListItem sx={{ mx: 0, px: 0 }}>
      <ListItemAvatar>
        <Avatar
          src={image}
          sx={{
            bgcolor: "primary.main",
          }}
        >
          {title?.charAt(0)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} secondary={subtitle} />
    </ListItem>
  );
};

export default DetailedImage;
