import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const CustomOptionsInput = ({
  control,
  name,
  label,
  type,
  errorFeedback,
  options,
  disabled,
}) => {
  let content = <></>;

  switch (type) {
    case "radio":
      content = (
        <FormControl sx={{ mb: 2 }} fullWidth error={Boolean(errorFeedback)}>
          <FormLabel
            sx={{ textTransform: "capitalize" }}
            id={`demo-radio-buttons-group-${label}`}
          >
            {label}
          </FormLabel>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                aria-labelledby={`demo-radio-buttons-group-${label}`}
                name={`${name}`}
              >
                {options?.map((option) => (
                  <FormControlLabel
                    sx={{ textTransform: "capitalize" }}
                    key={option.id}
                    value={option.id}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
          {errorFeedback && (
            <FormHelperText sx={{ color: "error.main" }}>
              {errorFeedback.message}
            </FormHelperText>
          )}
        </FormControl>
      );
      break;

    case "select":
      content = (
        <FormControl sx={{ mb: 2 }} fullWidth error={Boolean(errorFeedback)}>
          <InputLabel
            sx={{ textTransform: "capitalize" }}
            id={`select-label-${label}`}
          >
            {label}
          </InputLabel>
          <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                error={Boolean(error)}
                labelId={`select-label-${label}`}
                id={`select-${label}`}
                label={label}
                size="medium"
                {...field}
              >
                {options?.map((option) => (
                  <MenuItem
                    sx={{ textTransform: "capitalize" }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errorFeedback && (
            <FormHelperText sx={{ color: "error.main" }}>
              {errorFeedback.message}
            </FormHelperText>
          )}
        </FormControl>
      );
      break;

    case "autocomplete":
      content = (
        <FormControl
          sx={{
            mb: 2,
            "& .MuiFormLabel-root": {
              textTransform: "capitalize",
            },
          }}
          fullWidth
          error={Boolean(errorFeedback)}
          disabled={Boolean(disabled)}
        >
          <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { value, onChange, ref } = field;
              return (
                <Autocomplete
                  disabled={Boolean(disabled)}
                  size="medium"
                  value={
                    value
                      ? options?.find((option) => {
                          if (typeof value === "object" && "id" in value) {
                            return option.id === value.id;
                          }
                          return option.id === value;
                        }) ?? null
                      : null
                  }
                  onChange={(_e, newValue) => {
                    onChange(newValue?.id ?? null);
                  }}
                  getOptionLabel={(option) => option.label}
                  id={`combo-box-${label}`}
                  options={options || []}
                  getOptionKey={(option) => option.id}
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(error)}
                      inputRef={ref}
                      {...params}
                      label={label}
                    />
                  )}
                />
              );
            }}
          />
          {errorFeedback && (
            <FormHelperText sx={{ color: "error.main" }}>
              {errorFeedback.message}
            </FormHelperText>
          )}
        </FormControl>
      );
      break;

    default:
      break;
  }

  return <>{content}</>;
};

export default CustomOptionsInput;
