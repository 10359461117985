import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { AxiosError } from "axios";

const ErrorMsg = ({ error }) => {
  let message = error;
  if (error instanceof AxiosError) {
    message = error.response?.data.message;
  }
  return (
    <Box sx={{ maxWidth: "md", my: 6 }}>
      <Alert severity="error">{message}</Alert>
    </Box>
  );
};

export default ErrorMsg;
