import { useMutation } from "@tanstack/react-query";
import BaseCompanyStocksForm from "components/admin/company-stocks/BaseForm";
import queryClient from "config/queryClient";
import {
  CACHE_KEYS,
  IMAGE_DEFAULT_ENTRY,
  TITLE_CONTENT_DEFAULT_ENTRY,
  TITLE_IMAGE_CONTENT_DEFAULT_ENTRY,
} from "constants/common";
import { companyStocksDto } from "dto";
import toast from "react-hot-toast";
import { adminApi } from "services/api";
import {
  formatCompanyStocksDataHelper,
  formatCompanyStocksImagesHelper,
  generateDefaultFormsHelper,
  showMutationError,
} from "utils/common";

function getDefaultValuesHelper({ defaultValues, companyOptions }) {
  return {
    id: defaultValues.id,
    company_id: defaultValues.company_id
      ? defaultValues.company_id
      : companyOptions?.length
      ? companyOptions[0].id
      : "",
    meta: {
      website: defaultValues?.meta?.website ?? "",
      category: defaultValues?.meta?.category ?? "",
      companyName: defaultValues?.meta?.companyName ?? "",
    },
    price: {
      max: defaultValues?.steps?.stocks?.price?.max ?? 0,
      min: defaultValues?.steps?.stocks?.price?.max ?? 0,
      defaultValue: defaultValues?.steps?.stocks?.price?.defaultValue ?? 0,
    },
    range: {
      max: defaultValues?.steps?.stocks?.range?.max ?? 0,
      min: defaultValues?.steps?.stocks?.range?.min ?? 0,
      defaultValue: defaultValues?.steps?.stocks?.range?.defaultValue ?? 0,
    },
    header: generateDefaultFormsHelper(
      defaultValues?.header?.items,
      TITLE_CONTENT_DEFAULT_ENTRY
    ),
    taba_title: defaultValues?.taba?.title,
    taba_images: generateDefaultFormsHelper(
      defaultValues?.taba?.images?.map((item) => ({ image: item })) ?? [],
      IMAGE_DEFAULT_ENTRY
    ),
    taba_content: generateDefaultFormsHelper(
      defaultValues?.taba?.content,
      TITLE_CONTENT_DEFAULT_ENTRY
    ),
    tabb_title: defaultValues?.tabb?.title,
    tabb_content: generateDefaultFormsHelper(
      defaultValues?.tabb?.content,
      TITLE_IMAGE_CONTENT_DEFAULT_ENTRY
    ),
    tabc_title: defaultValues?.tabc?.title,
    tabc_images: generateDefaultFormsHelper(
      defaultValues?.tabc?.images?.map((item) => ({ image: item })) ?? [],
      IMAGE_DEFAULT_ENTRY
    ),
    tabc_content: generateDefaultFormsHelper(
      defaultValues?.tabc?.content,
      TITLE_CONTENT_DEFAULT_ENTRY
    ),
    tabd_title: defaultValues?.tabd?.title,
    tabd_content: generateDefaultFormsHelper(
      defaultValues?.tabd?.content,
      TITLE_CONTENT_DEFAULT_ENTRY
    ),
  };
}

const CompanyStocksEdit = ({ companyOptions, handleClose, defaultValues }) => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ id, formData }) =>
      adminApi.updateCompanyStockById({ id, payload: formData }),
    onSuccess: () => {
      toast.success("Company Stocks Updated Successfully");
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.STOCKS],
      });
    },
  });
  const submitHandler = async (value) => {
    try {
      const formData = new FormData();
      const formattedJson = formatCompanyStocksDataHelper(value);
      formData.append("payload", JSON.stringify(formattedJson));
      formatCompanyStocksImagesHelper({ payload: value, formData });
      await mutateAsync({
        id: defaultValues.id,
        formData,
      });
      handleClose();
    } catch (error) {
      showMutationError(error);
    }
  };
  return (
    <BaseCompanyStocksForm
      companyOptions={companyOptions}
      handleClose={handleClose}
      submitHandler={submitHandler}
      title={"Update"}
      isPending={isPending}
      defaultValues={getDefaultValuesHelper({ defaultValues, companyOptions })}
      dto={companyStocksDto.createCompanyStocksDto}
    />
  );
};

export default CompanyStocksEdit;
