import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { shareHolderDto } from "dto";
import { shareHolderInputs } from "constants/form-inputs";
import { ZenForm } from "components/form";
import { showMutationError } from "utils/common";
import { useMutation } from "@tanstack/react-query";
import { adminApi } from "services/api";
import queryClient from "config/queryClient";
import { CACHE_KEYS } from "constants/common";
import toast from "react-hot-toast";

function returnDefaultValues({ companyOptions }) {
  return {
    author: "",
    title: "",
    content: "",
    company_id: companyOptions?.length ? companyOptions[0].id : "",
  };
}

const ShareHolderAdd = ({ handleClose, companyOptions }) => {
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: returnDefaultValues({ companyOptions }),
    resolver: yupResolver(shareHolderDto.createShareHolder),
  });

  const { isPending, mutateAsync } = useMutation({
    mutationFn: adminApi.insertShareHolder,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.SHARE_HOLDERS],
      });
    },
  });
  const submitHandler = async (value) => {
    try {
      const formData = new FormData();
      Object.keys(value).forEach((key) => {
        formData.append(key, value[key]);
      });
      await mutateAsync(formData);
      handleClose();
      toast.success("Shareholder Entry created successfully");
      reset();
    } catch (error) {
      showMutationError(error);
    }
  };

  return (
    <Box sx={{ m: 4 }}>
      <form noValidate onSubmit={handleSubmit(submitHandler)}>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={shareHolderInputs({ companyOptions })}
        />
        <Button
          disabled={isPending}
          type="submit"
          variant="contained"
          color="success"
        >
          {isPending ? "Creating..." : "Create"}
        </Button>
      </form>
    </Box>
  );
};

export default ShareHolderAdd;
