import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes, useNavigate } from "react-router-dom";
import Loader from "./components/UI/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import Dashboard from "./components/dashboard/Dashboard";
import { ProtectedLayout } from "./components/providers/AuthGuard";
import AdminNavigation from "components/admin/navigation";
import { ADMIN_ROLE, rolePrefix } from "constants/common";
import { apiInstance } from "services/api.service";
import { Toaster } from "react-hot-toast";
import usePageAnalytics from "hooks/usePageAnalytics";

function App() {
  // Initialize Google Analytics
  usePageAnalytics();
  // Initialize Auth
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    user,
    getAccessTokenSilently,
  } = useAuth0();
  const [redirectLoading, setRedirectLoading] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function redirectAuth() {
      try {
        setRedirectLoading(true);
        await loginWithRedirect({
          appState: {
            returnTo: "/dashboard/home",
          },
        });
      } catch (error) {
      } finally {
        setRedirectLoading(false);
      }
    }
    if (!isAuthenticated && !isLoading) {
      redirectAuth();
    } else if (isAuthenticated && !isLoading && user) {
      const roles = user?.[rolePrefix];
      if (roles.includes(ADMIN_ROLE)) {
        getAccessTokenSilently().then((token) => {
          apiInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
          localStorage.setItem("token", token);
          navigate("/admin", { replace: true });
        });
        return;
      } else navigate("/dashboard/home", { replace: true });
    }
  }, [isAuthenticated, isLoading, user]);

  if (isLoading || redirectLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | Valoo</title>
      </Helmet>
      <Toaster position="top-center" />
      <Routes>
        <Route path="/" Component={Loader} />
        <Route path="/dashboard" element={<ProtectedLayout />}>
          <Route path="home" element={<Dashboard />} />
        </Route>
        <Route path="/callback" Component={Loader} />
        <Route path="/admin/*" element={<AdminNavigation />} />
      </Routes>
    </>
  );
}

export default App;
