import { useMutation, useQuery } from "@tanstack/react-query";
import { adminApi, userApi } from "services/api";

export const useCompanyOptions = () => {
  return useQuery({
    queryKey: ["companies-options"],
    queryFn: () => adminApi.fetchAllCompanies(),
    select: (data) => {
      const companiesData = data?.data?.companiesData ?? [];
      const options = companiesData.map((company) => ({
        id: company.id,
        label: company.companyName,
      }));
      return options;
    },
  });
};

export const useUserOptions = () => {
  return useQuery({
    queryKey: ["users-options"],
    queryFn: () => adminApi.fetchUserOptions(),
    select: (data) => {
      const usersData = data?.data ?? [];
      const options = usersData.map((user) => ({
        id: user.id,
        label: `${user.fullname ?? ""} ${user.email}`,
      }));
      return options;
    },
  });
};

export const useUpdateCRM= ()=>{
 return useMutation({
  mutationFn:userApi.updateCRM
 })
}