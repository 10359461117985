import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { ZenForm } from "components/form";
import { companyStocksInputs } from "constants/form-inputs";
import { useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import DividerChip from "../UI/DividerChip";
import {
  IMAGE_DEFAULT_ENTRY,
  TITLE_CONTENT_DEFAULT_ENTRY,
  TITLE_IMAGE_CONTENT_DEFAULT_ENTRY,
} from "constants/common";
import FieldArrayForm from "components/form/FieldArrayForm";

const HEADER_FORM_KEY = "header";
const TABA_IMAGES_FORM_KEY = "taba_images";
const BaseCompanyStocksForm = ({
  companyOptions,
  defaultValues,
  submitHandler,
  title,
  dto,
  isPending,
}) => {
  //hook form
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: yupResolver(dto),
  });

  const {
    fields: h_Fields,
    append: h_Append,
    remove: h_Remove,
  } = useFieldArray({
    control,
    name: HEADER_FORM_KEY,
    rules: {
      minLength: 1,
    },
  });

  const {
    fields: taba_image_Fields,
    append: taba_image_Append,
    remove: taba_image_Remove,
  } = useFieldArray({
    control,
    name: TABA_IMAGES_FORM_KEY,
    rules: {
      minLength: 1,
    },
  });

  const {
    fields: taba_content_Fields,
    append: taba_content_Append,
    remove: taba_content_Remove,
  } = useFieldArray({
    control,
    name: "taba_content",
    rules: {
      minLength: 1,
    },
  });

  const {
    fields: tabc_image_Fields,
    append: tabc_image_Append,
    remove: tabc_image_Remove,
  } = useFieldArray({
    control,
    name: "tabc_images",
    rules: {
      minLength: 1,
    },
  });

  const {
    fields: tabb_content_Fields,
    append: tabb_content_Append,
    remove: tabb_content_Remove,
  } = useFieldArray({
    control,
    name: "tabb_content",
    rules: {
      minLength: 1,
    },
  });

  const {
    fields: tabc_content_Fields,
    append: tabc_content_Append,
    remove: tabc_content_Remove,
  } = useFieldArray({
    control,
    name: "tabc_content",
    rules: {
      minLength: 1,
    },
  });

  const {
    fields: tabd_content_Fields,
    append: tabd_content_Append,
    remove: tabd_content_Remove,
  } = useFieldArray({
    control,
    name: "tabd_content",
    rules: {
      minLength: 1,
    },
  });

  const INPUTS = useMemo(() => {
    return companyStocksInputs({ companyOptions });
  }, [companyOptions]);

  return (
    <Box sx={{ m: 4 }}>
      <form noValidate onSubmit={handleSubmit(submitHandler)}>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={INPUTS.BASE}
        />
        <DividerChip>Meta</DividerChip>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={INPUTS.META}
        />
        <DividerChip>Steps</DividerChip>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={INPUTS.STEPS}
        />
        <DividerChip>Header</DividerChip>
        <FieldArrayForm
          {...{
            append: h_Append,
            remove: h_Remove,
            fields: h_Fields,
            control,
            errors,
            defaultEntry: TITLE_CONTENT_DEFAULT_ENTRY,
            formKey: HEADER_FORM_KEY,
            arrInputs: INPUTS.HEADER,
            direction: "vertical",
            formLabel: "Header",
          }}
        />
        {/* Tab A */}
        <DividerChip>Tab A</DividerChip>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={INPUTS.TabA.BASE}
        />
        <FieldArrayForm
          {...{
            append: taba_image_Append,
            remove: taba_image_Remove,
            fields: taba_image_Fields,
            control,
            errors,
            defaultEntry: IMAGE_DEFAULT_ENTRY,
            formKey: TABA_IMAGES_FORM_KEY,
            arrInputs: INPUTS.TabA.IMAGES,
            direction: "vertical",
            formLabel: "Tab A Images",
          }}
        />
        <FieldArrayForm
          {...{
            append: taba_content_Append,
            remove: taba_content_Remove,
            fields: taba_content_Fields,
            control,
            errors,
            defaultEntry: TITLE_CONTENT_DEFAULT_ENTRY,
            formKey: "taba_content",
            arrInputs: INPUTS.TabA.CONTENT,
            direction: "vertical",
            formLabel: "Tab A Content",
          }}
        />
        {/* Tab B*/}
        <DividerChip>Tab B</DividerChip>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={INPUTS.TabB.BASE}
        />
        <FieldArrayForm
          {...{
            append: tabb_content_Append,
            remove: tabb_content_Remove,
            fields: tabb_content_Fields,
            control,
            errors,
            defaultEntry: TITLE_IMAGE_CONTENT_DEFAULT_ENTRY,
            formKey: "tabb_content",
            arrInputs: INPUTS.TabB.CONTENT,
            direction: "vertical",
            formLabel: "Tab B Content",
          }}
        />
        {/* tab c */}
        <DividerChip>Tab C</DividerChip>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={INPUTS.TabC.BASE}
        />
        <FieldArrayForm
          {...{
            append: tabc_image_Append,
            remove: tabc_image_Remove,
            fields: tabc_image_Fields,
            control,
            errors,
            defaultEntry: IMAGE_DEFAULT_ENTRY,
            formKey: "tabc_images",
            arrInputs: INPUTS.TabC.IMAGES,
            direction: "vertical",
            formLabel: "Tab C Images",
          }}
        />
        <FieldArrayForm
          {...{
            append: tabc_content_Append,
            remove: tabc_content_Remove,
            fields: tabc_content_Fields,
            control,
            errors,
            defaultEntry: TITLE_CONTENT_DEFAULT_ENTRY,
            formKey: "tabc_content",
            arrInputs: INPUTS.TabC.CONTENT,
            direction: "vertical",
            formLabel: "Tab C Content",
          }}
        />
        {/* Tab B*/}
        {/* Tab D */}
        <DividerChip>Tab D</DividerChip>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={INPUTS.TabD.BASE}
        />
        <FieldArrayForm
          {...{
            append: tabd_content_Append,
            remove: tabd_content_Remove,
            fields: tabd_content_Fields,
            control,
            errors,
            defaultEntry: TITLE_CONTENT_DEFAULT_ENTRY,
            formKey: "tabd_content",
            arrInputs: INPUTS.TabD.CONTENT,
            direction: "vertical",
            formLabel: "Tab D Content",
          }}
        />
        <Button
          disabled={isPending}
          my={4}
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
        >
          {isPending ? "Saving..." : title}
        </Button>
      </form>
    </Box>
  );
};

export default BaseCompanyStocksForm;
