import { MODEL_INITIAL_STATE } from "constants/common";
import { useCallback, useState } from "react";

export const useModal = (initialState) => {
  const [modalState, setModalState] = useState(
    initialState || MODEL_INITIAL_STATE
  );
  const modalStateHandler = useCallback(
    (key, val) =>
      setModalState((prev) => ({
        ...prev,
        [key]: val,
      })),
    []
  );
  return {
    modalState,
    setModalState,
    modalStateHandler,
  };
};
