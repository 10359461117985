import * as yup from "yup";

// Yup validation schema for the Company model
const yupNumber = yup
  .number()
  .transform((value) => (value === "" || value === null ? null : +value));

const baseInputs = yup.object().shape({
  company_name: yup.string().required("Company name is required."),
  listing_preference: yup.string().required("Listing preference is required."),
  buy_rating: yupNumber.required().min(0, "Buy rating must be at least 0"),
  sell_rating: yupNumber.required().min(0, "Sell rating must be at least 0"),
  docusend: yup.string().required("Docusend is required."),
});

export const createCompanyDto = yup
  .object()
  .shape({
    image_url: yup.mixed().required("Please upload an image."),
  })
  .concat(baseInputs);

export const editCompanyDto = createCompanyDto
  .clone()
  .shape({
    id: yup.string().required(),
    image_url: yup.mixed().nullable(),
  })
  .concat(baseInputs);
