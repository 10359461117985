import React, { useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

const FormInput = ({
  control,
  name,
  label,
  type,
  placeholder,
  extraStyle,
  rows,
}) => {
  const isMultiline = useMemo(() => type === "multiline", [type]);
  return (
    <FormControl fullWidth sx={extraStyle ? extraStyle : { mb: 2 }}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <TextField
                sx={{
                  "& .MuiFormLabel-root": {
                    textTransform: "capitalize",
                  },
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                type={isMultiline ? "text" : type}
                {...field}
                size="medium"
                label={label}
                error={Boolean(error)}
                placeholder={placeholder || " "}
                multiline={isMultiline}
                rows={rows}
              />
              {Boolean(error) && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {error?.message}
                </FormHelperText>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default FormInput;
