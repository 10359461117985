import * as yup from "yup";

const baseSellSchema = yup.object().shape({
  company_id: yup.number().required("Company is required."),
  user_id: yup.number().required("User is required."),
  number_of_stocks: yup.number().required("Number of stocks is required."),
  price_per_stock: yup.number().required("Price per stock is required."),
  steps: yup.number().required("Steps is required."),
});

export const createWalletSell = baseSellSchema.clone().shape({
  sell_percentages_raised: yup
    .number()
    .required("Sell percentages raised is required."),
});

export const editWalletSell = createWalletSell.clone().shape({
  id: yup.number().required("ID is required."),
});

export const createWalletBuy = baseSellSchema.clone().shape({
  progress_text: yup.string().required("Progress text is required."),
});

export const editWalletBuy = createWalletBuy.clone().shape({
  id: yup.number().required("ID is required."),
});
