
export const crmFieldsInfo = [
    {
        fieldKey: "accountname",
        formLabel: "Full Name",
        description: "The name of the customer as entered during signup on Valoo."
    },
    {
        fieldKey: "emailaddress1",
        formLabel: "Email Address",
        description: "The customer's email address provided during the signup process."
    },
    {
        fieldKey: "telephone1",
        formLabel: "Phone Number",
        description: "The phone number provided by the customer when signing up on Valoo."
    },
    {
        fieldKey: "pcfsystemfield88",
        formLabel: "Are you a buyer or a seller?",
        description: "Identifies the user type.",
        valueDescription: "1 = Seller, 2 = Buyer, 3 = Both Buyer and Seller, 4 = Company"
    },
    {
        fieldKey: "pcfsystemfield721",
        formLabel: "I want to know about the latest news",
        description: "Indicates whether the user wants to receive newsletters.",
        valueDescription: "1 = Yes, 2 = No"
    },
    {
        fieldKey: "pcfsystemfield722",
        formLabel: "Buyer Preferences in Industries",
        description: "User's industry preferences. Possible options: AI, FinTech, Cyber, AdTech, AgriTech, Other.",
        valueDescription: "Fields pfsystemfield722 to pfsystemfield727 correspond to preferences. 1 = Yes, 2 = No"
    },
    {
        fieldKey: "pcfsystemfield728",
        formLabel: "Investment Amount (Only for Buyers or Buyer and Sellers)",
        description: "The investment amount in USD."
    },
    {
        fieldKey: "pcfsystemfield729",
        formLabel: "Investment Period (Only for Buyers or Buyer and Sellers)",
        description: "The investment period in years, ranging from 2 to 10."
    },
    {
        fieldKey: "pcfsystemfield108",
        formLabel: "Company Name (Only for Sellers or Buyer and Sellers)",
        description: "The name of the company to sell shares or options."
    },
    {
        fieldKey: "pcfsystemfield731",
        formLabel: "Amount of Shares/Options (Only for Sellers or Buyer and Sellers)",
        description: "The number of shares or options to sell."
    },
    {
        fieldKey: "pcfsystemfield732",
        formLabel: "Target Price (Only for Sellers or Buyer and Sellers)",
        description: "The target price for selling shares or options."
    },
    {
        fieldKey: "pcfsystemfield733",
        formLabel: "Share or Options (Only for Sellers or Buyer and Sellers)",
        description: "Specifies whether the asset is a share or option.",
        valueDescription: "1 = Options, 2 = Share, 3 = Not Sure"
    },
    {
        fieldKey: "pcfsystemfield717",
        formLabel: "Liquid Assets (Only for Buyers or Buyer and Sellers)",
        description: "The amount of liquid assets in USD."
    },
    {
        fieldKey: "status",
        formLabel: "User Status",
        description: "User Status represnts the current status of the user. Possible options: Pending, Approved, Survey, Registration."
    },
    {
        fieldKey: "rating",
        formLabel: "Order Rating",
        description: "rating is the order in which the companies on the left panel are displayed. that way we can promote certain companies"
    },
    {
        fieldKey: "listing_preference",
        formLabel: "Listing Preference",
        description: "Filtering the left pannel of companies based on the user's preferences.",
        valueDescription: "Possible options: All, Nothing, Buy, Sell"
    },
    {
        fieldKey: "docusend",
        formLabel: "Document Send",
        description: "'Stay Updated' Article link for the user to download. (PDF file)"
    },
    {
        fieldKey: "has_signed",
        formLabel: "Has Signed?",
        description: "Boolean Value that indicates whether the user has signed the NDA with company or not."
    },
    {
        fieldKey: "number_of_stocks",
        formLabel: "Number of Stocks",
        description: "the number of stocks being sold/bought in the transcation."
    },
    {
        fieldKey: "price_per_stock",
        formLabel: "Price per Stock",
        description: "the price per stock being sold/bought in the transcation."
    },
    {
        fieldKey: "steps",
        formLabel: "Steps",
        description: "the step of the user in the buyer/selling process. there are about 5-6 steps."
    },

];

export const crmFieldsInfoLookup = crmFieldsInfo.reduce((acc, curr) => {
    acc[curr.fieldKey] = curr;
    return acc;
}, {});