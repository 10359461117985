import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { editUserInputs } from "constants/form-inputs";
import { ZenForm } from "components/form";
import { showMutationError } from "utils/common";
import { useMutation } from "@tanstack/react-query";
import { adminApi } from "services/api";
import queryClient from "config/queryClient";
import { CACHE_KEYS } from "constants/common";
import toast from "react-hot-toast";
import React from "react";
import { userDto } from "dto";

function returnDefaultValues(user) {
  return {
    id: user?.id,
    status: user?.status ?? "pending",
  };
}

const EditUser = ({ defaultValues, handleClose }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: returnDefaultValues(defaultValues),
    resolver: yupResolver(userDto.editUserDto),
  });

  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ id, formData }) =>
      adminApi.updateUser({ id, payload: formData }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.PAGINATED_USERS],
      });
    },
  });
  const submitHandler = async (value) => {
    try {
      await mutateAsync({
        id: defaultValues.id,
        formData: {
          status: value.status,
        },
      });
      handleClose();
      toast.success("User updated successfully");
    } catch (error) {
      showMutationError(error);
    }
  };

  return (
    <Box sx={{ m: 4 }}>
      <form noValidate onSubmit={handleSubmit(submitHandler)}>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={editUserInputs}
        />
        <Button
          disabled={isPending}
          type="submit"
          variant="contained"
          color="success"
        >
          {isPending ? "Updating..." : "Update"}
        </Button>
      </form>
    </Box>
  );
};

export default EditUser;
