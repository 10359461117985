import React from "react";
import { Controller } from "react-hook-form";
import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Stack,
} from "@mui/material";
import ThumbnailImage from "components/admin/UI/ThumbnailImage";
import { isImageUrl, validateImage } from "utils/common";
import toast from "react-hot-toast";
import ImageIcon from "@mui/icons-material/Image";

const CustomFileInput = ({ control, name, errorFeedback, extraStyle }) => {
  const [imageSrc, setImageSrc] = React.useState(null);
  return (
    <FormControl fullWidth sx={extraStyle ? extraStyle : { mb: 4 }}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <>
            <Stack gap={2} direction="row" alignItems="center">
              <OutlinedInput
                type="file"
                onChange={(e) => {
                  const targetFile = e.target?.files?.[0];
                  if (!targetFile) return;
                  validateImage(targetFile)
                    .then((url) => {
                      field.onChange(e.target?.files?.[0]); // Assign first file to the field
                      setImageSrc(url);
                    })
                    .catch((error) => {
                      toast.error(error);
                    });
                }}
                inputProps={{
                  accept: "image/jpeg, image/png, image/jpg", // Restrict to JPEG, PNG, and JPG files
                }}
                error={Boolean(error)}
                sx={{
                  "& .MuiFormLabel-root": {
                    textTransform: "capitalize",
                  },
                }}
              />
              <Box sx={{ flex: "0.4" }}>
                <ThumbnailImage
                  size={60}
                  src={isImageUrl(field.value) ? field.value : imageSrc}
                  icon={<ImageIcon />}
                />
              </Box>
            </Stack>
            {errorFeedback && error && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errorFeedback.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export default CustomFileInput;
