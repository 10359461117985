import HeaderRenderer from "components/admin/UI/InfoTooltip";
import UsersStatusEnum from "./UsersStatusEnum";

const commonImageConfig = {
  type: "file",
  label: "Image",
  dropConfig: {
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, // 10 MB in bytes
    hint: "You can upload a PNG, JPEG, or JPG image. Maximum file size is 10 MB.",
  },
};

const commonPdfConfig = {
  type: "file",
  label: "PDF",
  dropConfig: {
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, // 10 MB in bytes
    hint: "You can upload a PDF. Maximum file size is 10 MB.",
  },
};

const titleContentInputs = [
  {
    name: "title",
    type: "text",
    label: "Title",
    placeholder: "Example Title",
  },
  {
    name: "content",
    type: "multiline",
    label: "Content",
    placeholder: "Example Content",
    rows: 3,
  },
];
export const baseCompanyInputs = [
  {
    name: "company_name",
    type: "text",
    label: "Company Name",
    placeholder: "ABC Company",
  },
  {
    name: "listing_preference",
    type: "text",
    label: "Listing Preference",
    placeholder: "all",
  },
  {
    name: "buy_rating",
    type: "number",
    label: "Buy Rating",
    placeholder: "4.5",
  },
  {
    name: "docusend",
    type: "text",
    label: "Docusend",
  },
  {
    name: "sell_rating",
    type: "number",
    label: "Sell Rating",
    placeholder: "4.5",
  },
  {
    name: "image_url",
    ...commonImageConfig,
    label: "Company Image",
  },
];

export const newsLetterInputs = [
  {
    name: "headline",
    type: "text",
    label: "Headline",
    placeholder: "Headline of the newsletter",
  },
  {
    name: "link",
    type: "text",
    label: "Link",
    placeholder: "https://www.example.com",
  },
  {
    name: "image",
    ...commonImageConfig,
    label: "Image",
  },
];

export const stayUpdatedInputs = [
  {
    name: "title",
    type: "text",
    label: "Title",
    placeholder: "Example Title",
  },
  {
    name: "link",
    type: "text",
    label: "Link",
    placeholder: "https://www.example.com",
  },
  {
    name: "featured_image",
    ...commonImageConfig,
    label: "Featured Image",
  },
];

export const shareHolderInputs = ({ companyOptions }) => [
  {
    name: "author",
    type: "text",
    label: "Author",
    placeholder: "John Doe",
  },
  {
    name: "title",
    type: "text",
    label: "Title",
    placeholder: "Example Title",
  },
  {
    name: "content",
    type: "text",
    label: "Content",
    placeholder: "Example Content",
  },
  {
    name: "company_id",
    type: "autocomplete",
    label: "Choose Company",
    options: companyOptions,
  },
  {
    name: "featured_image",
    ...commonImageConfig,
    label: "Featured Image",
  },
  {
    name: "file_name",
    ...commonPdfConfig,
    label: "File",
  },
];

export const baseWalletInputs = ({ companyOptions, userOptions }) => {
  return [
    {
      name: "company_id",
      type: "autocomplete",
      label: "Choose Company",
      options: companyOptions,
    },
    {
      name: "user_id",
      type: "autocomplete",
      label: "Choose User",
      options: userOptions,
    },
    {
      name: "number_of_stocks",
      type: "number",
      label: "No. of Stocks",
      placeholder: "200",
    },
    {
      name: "price_per_stock",
      type: "number",
      label: "Price Per Stock",
      placeholder: "$100",
    },
    {
      name: "steps",
      type: "number",
      label: "Steps",
      placeholder: "10",
    },
  ];
};
export const walletSellInputs = ({ companyOptions, userOptions }) => {
  return [
    ...baseWalletInputs({ companyOptions, userOptions }),
    {
      name: "sell_percentages_raised",
      type: "number",
      label: "Sell% Raised",
      placeholder: "20.5",
    },
  ];
};

export const walletBuyInputs = ({ companyOptions, userOptions }) => {
  return [
    ...baseWalletInputs({ companyOptions, userOptions }),
    {
      name: "progress_text",
      type: "text",
      label: "Progress Text",
      placeholder: "Sold Out",
    },
  ];
};

export const ndaInputs = ({ companyOptions, userOptions, isDisabled }) => [
  {
    name: "company_id",
    type: "autocomplete",
    label: "Choose Company",
    options: companyOptions,
    disabled: isDisabled,
  },
  {
    name: "user_id",
    type: "autocomplete",
    label: "Choose User",
    options: userOptions,
    disabled: isDisabled,
  },
  {
    name: "has_signed",
    type: "switch",
    label: "Has Signed",
    crmKey:"has_signed",
    renderHeader:HeaderRenderer,
  },
];

export const companyDataWalletInputs = ({ companyOptions }) => ({
  common: [
    {
      name: "company_id",
      type: "autocomplete",
      label: "Choose Company",
      options: companyOptions,
    },
  ],
  meta: [
    {
      name: "category",
      type: "text",
      label: "Category",
      placeholder: "Category",
    },
    {
      name: "companyLogo",
      type: "text",
      label: "Company Symbol",
      placeholder: "T",
    },
    {
      name: "companyName",
      type: "text",
      label: "Company Name",
      placeholder: "Tata Consultancy Services",
    },
  ],
  header: titleContentInputs,
  main_images: [
    {
      name: "image",
      type: "file",
      label: "Image",
    },
  ],
  main_paragraphs: titleContentInputs,
});

export const companyStocksInputs = ({ companyOptions }) => ({
  BASE: [
    {
      name: "company_id",
      type: "autocomplete",
      label: "Choose Company",
      options: companyOptions,
    },
  ],
  META: [
    {
      name: "meta.website",
      type: "text",
      label: "Website",
      placeholder: "example.com",
    },
    {
      name: "meta.category",
      type: "text",
      label: "Category",
      placeholder: "Category",
    },
    {
      name: "meta.companyName",
      type: "text",
      label: "Company Name",
      placeholder: "Company Name",
    },
  ],
  STEPS: [
    {
      name: "price.max",
      type: "number",
      label: "Max Price",
      placeholder: "$100",
    },
    {
      name: "price.min",
      type: "number",
      label: "Min Price",
      placeholder: "$100",
    },
    {
      name: "price.defaultValue",
      type: "number",
      label: "Default Price",
      placeholder: "$100",
    },
    {
      name: "range.max",
      type: "number",
      label: "Max Range",
      placeholder: "$100",
    },
    {
      name: "range.min",
      type: "number",
      label: "Min Range",
      placeholder: "$100",
    },
    {
      name: "range.defaultValue",
      type: "number",
      label: "Default Range",
      placeholder: "$100",
    },
  ],
  HEADER: titleContentInputs,
  TabA: {
    BASE: [
      {
        name: "taba_title",
        type: "text",
        label: "Tab A Title",
        placeholder: "TabA Title",
      },
    ],
    IMAGES: [
      {
        name: "image",
        type: "file",
        label: "Image",
      },
    ],
    CONTENT: titleContentInputs,
  },
  TabB: {
    BASE: [
      {
        name: "tabb_title",
        type: "text",
        label: "Tab B Title",
        placeholder: "TabB Title",
      },
    ],
    CONTENT: [
      ...titleContentInputs,
      {
        name: "image",
        type: "file",
        label: "Image",
      },
    ],
  },
  TabC: {
    BASE: [
      {
        name: "tabc_title",
        type: "text",
        label: "Tab C Title",
        placeholder: "TabC Title",
      },
    ],
    IMAGES: [
      {
        name: "image",
        type: "file",
        label: "Image",
      },
    ],
    CONTENT: titleContentInputs,
  },
  TabD: {
    BASE: [
      {
        name: "tabd_title",
        type: "text",
        label: "Tab D Title",
        placeholder: "TabD Title",
      },
    ],
    CONTENT: [...titleContentInputs],
  },
});

export const editUserInputs = [
  {
    name: "status",
    type: "select",
    label: "Status",
    options: [
      { label: "Pending", id: UsersStatusEnum.Pending },
      { label: "Approved", id: UsersStatusEnum.Approved },
      { label: "Survey", id: UsersStatusEnum.Survey },
      { label: "Registration", id: UsersStatusEnum.Registration },
    ],
  },
];
