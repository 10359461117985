import * as yup from "yup";

const baseInputs = yup.object().shape({
  headline: yup.string().required("Newsletter name is required."),
  link: yup
    .string()
    .url("Link must be a valid URL")
    .required("Link is required."),
});

export const createNewsletterDto = yup
  .object()
  .shape({
    image: yup.mixed().required("Please upload an image."),
  })
  .concat(baseInputs);

export const editNewsletterDto = createNewsletterDto
  .clone()
  .shape({
    id: yup.string().required(),
    image: yup.mixed().nullable(),
  })
  .concat(baseInputs);
