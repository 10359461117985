import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { stayUpdatedDto } from "dto";
import { stayUpdatedInputs } from "constants/form-inputs";
import { ZenForm } from "components/form";
import { showMutationError } from "utils/common";
import { useMutation } from "@tanstack/react-query";
import { adminApi } from "services/api";
import queryClient from "config/queryClient";
import { CACHE_KEYS } from "constants/common";
import toast from "react-hot-toast";

function returnDefaultValues() {
  return {
    title: "",
    link: "",
  };
}

const AddStayUpdated = ({ handleClose }) => {
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: returnDefaultValues(),
    resolver: yupResolver(stayUpdatedDto.createStayUpdatedDto),
  });

  const { isPending, mutateAsync } = useMutation({
    mutationFn: adminApi.insertStayUpdated,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.STAY_UPDATED],
      });
    },
  });
  const submitHandler = async (value) => {
    try {
      const formData = new FormData();
      Object.keys(value).forEach((key) => {
        formData.append(key, value[key]);
      });
      await mutateAsync(formData);
      handleClose();
      toast.success("Stay Updated created successfully");
      reset();
    } catch (error) {
      showMutationError(error);
    }
  };

  return (
    <Box sx={{ m: 4 }}>
      <form noValidate onSubmit={handleSubmit(submitHandler)}>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={stayUpdatedInputs}
        />
        <Button
          disabled={isPending}
          type="submit"
          variant="contained"
          color="success"
        >
          {isPending ? "Creating..." : "Create"}
        </Button>
      </form>
    </Box>
  );
};

export default AddStayUpdated;
