import * as yup from "yup";

import * as common from "dto/common";

const baseInputs = yup.object().shape({
  company_id: yup.number().required("Company is required."),
  category: yup.string().required("Category is required."),
  companyLogo: yup.string().required("Company Logo is required."),
  companyName: yup.string().required("Company Name is required."),
  header: yup
    .array()
    .of(common.titleContentFormSchema)
    .required("Header is required.")
    .min(1)
    .max(10),

  main_paragraphs: yup
    .array()
    .of(common.titleContentFormSchema.clone())
    .required("Main Paragraphs is required.")
    .min(1)
    .max(10),
});

export const createCompanyWalletDto = baseInputs.clone().shape({
  main_images: yup
    .array()
    .of(common.imageFormSchema)
    .required("Main Images is required.")
    .min(1)
    .max(10),
});

export const editCompanyWalletDto = createCompanyWalletDto.clone().shape({
  id: yup.number().required("ID is required."),
  main_images: yup.array().of(common.imageFormSchema).min(1).max(10),
});
