import Grid from "@mui/material/Grid";
import FormInput from "../form/FormInput";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CustomFileInput from "./CustomFileInput";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CustomOptionsInput from "./OptionsInput";
import { Button, Divider } from "@mui/material";
const FieldArrayForm = ({
  errors,
  control,
  fields,
  direction,
  formKey,
  append,
  remove,
  defaultEntry,
  arrInputs,
  formLabel,
}) => {
  const renderInputField = (input, index) => {
    const name = `${formKey}.${index}.${input.name}`;
    const errorFeedback =
      errors[formKey] && errors[formKey][index]
        ? errors[formKey][index][input.name]
        : undefined;

    switch (input.type) {
      case "file":
        return (
          <CustomFileInput
            control={control}
            label={input.label}
            name={name}
            errorFeedback={errorFeedback}
          />
        );

      case "text":
      case "email":
      case "password":
      case "number":
      case "multiline":
        return (
          <FormInput
            control={control}
            label={input.label}
            placeholder={input.placeholder ?? ""}
            name={name}
            type={input.type}
            errorFeedback={errorFeedback}
            rows={input.rows ?? 1}
          />
        );

      case "select":
      case "radio":
      case "checkbox":
        return (
          <CustomOptionsInput
            control={control}
            label={input.label}
            placeholder={input.placeholder ?? ""}
            name={name}
            type={input.type}
            options={input.options}
            errorFeedback={errorFeedback}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Stack direction="column" spacing={2} py={1}>
      <Stack direction="column" mb={0}>
        {fields.map((field, index) => (
          <Grid
            key={field.id}
            container
            columnSpacing={1}
            rowSpacing={0}
            mb={0}
          >
            {arrInputs.map((input) => (
              <Grid
                key={input.name}
                item
                sm={direction === "vertical" ? 12 : 6}
                sx={{ width: "100%" }}
              >
                {renderInputField(input, index)}
              </Grid>
            ))}
            {index > 0 && (
              <Box sx={{ width: "100%" }}>
                <Divider sx={{ mb: 2 }} textAlign="right">
                  <Button
                    size="small"
                    onClick={() => remove(index)}
                    color="error"
                    variant="outlined"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>{formLabel}&nbsp;Entry</span>
                    <DeleteIcon />
                  </Button>
                </Divider>
              </Box>
            )}
          </Grid>
        ))}
      </Stack>

      <Box sx={{ textAlign: "right", m: 0 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          sx={{ m: 0 }}
          onClick={() => append(defaultEntry)}
        >
          <AddIcon /> {formLabel} Entry
        </Button>
      </Box>
    </Stack>
  );
};

export default FieldArrayForm;
