import { Box, Stack } from "@mui/material";
import ThumbnailImage from "../UI/ThumbnailImage";

const ImageGallery = ({ imageUrls }) => {
  return (
    <Stack width={"100%"} flexWrap={"wrap"} gap={2} my={1} direction="row">
      {imageUrls.map((url) => (
        <Box key={url}>
          <ThumbnailImage size={50} key={url} src={url} />
        </Box>
      ))}
    </Stack>
  );
};

export default ImageGallery;
