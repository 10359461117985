import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { shareHolderDto } from "dto";
import { shareHolderInputs } from "constants/form-inputs";
import { ZenForm } from "components/form";
import { showMutationError, updateFormDataHelper } from "utils/common";
import { useMutation } from "@tanstack/react-query";
import { adminApi } from "services/api";
import queryClient from "config/queryClient";
import { CACHE_KEYS } from "constants/common";
import toast from "react-hot-toast";

function returnDefaultValues({ defaultValues, companyOptions }) {
  const result = {
    id: defaultValues.article_id,
    author: defaultValues.author ?? "",
    title: defaultValues.title ?? "",
    content: defaultValues.content ?? "",
    company_id: defaultValues.company_id
      ? defaultValues.company_id
      : companyOptions?.length
      ? companyOptions[0].id
      : "",
  };
  return result;
}

const ShareHolderEdit = ({ handleClose, defaultValues, companyOptions }) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: returnDefaultValues({ defaultValues, companyOptions }),
    resolver: yupResolver(shareHolderDto.editShareHolder),
  });

  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ id, formData }) =>
      adminApi.updateShareHolderById({ id, payload: formData }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.SHARE_HOLDERS],
      });
    },
  });
  const submitHandler = async (value) => {
    try {
      const formData = updateFormDataHelper(value, dirtyFields);
      await mutateAsync({
        id: defaultValues.article_id,
        formData,
      });
      handleClose();
      toast.success("Shareholder updated successfully");
    } catch (error) {
      showMutationError(error);
    }
  };

  return (
    <Box sx={{ m: 4 }}>
      <form noValidate onSubmit={handleSubmit(submitHandler)}>
        <ZenForm
          control={control}
          errors={errors}
          direction="vertical"
          inputs={shareHolderInputs({ companyOptions })}
        />
        <Button
          disabled={isPending}
          type="submit"
          variant="contained"
          color="primary"
        >
          {isPending ? "Updating..." : "Update"}
        </Button>
      </form>
    </Box>
  );
};

export default ShareHolderEdit;
