import { useMutation } from "@tanstack/react-query";
import BaseCompanyWalletForm from "components/admin/company-wallet/BaseForm";
import queryClient from "config/queryClient";
import {
  CACHE_KEYS,
  IMAGE_DEFAULT_ENTRY,
  TITLE_CONTENT_DEFAULT_ENTRY,
} from "constants/common";
import { companyWalletDto } from "dto";
import toast from "react-hot-toast";
import { adminApi } from "services/api";
import {
  formatCompanyDataWalletHelper,
  generateDefaultFormsHelper,
  isImageUrl,
  showMutationError,
} from "utils/common";

function getDefaultValuesHelper({ companyOptions, defaultValues }) {
  const main = { ...(defaultValues?.main ?? {}) };
  delete main.images;
  return {
    id: defaultValues.id,
    company_id: defaultValues.company_id
      ? defaultValues.company_id
      : companyOptions?.length
      ? companyOptions[0].id
      : "",
    category: defaultValues?.meta?.category ?? "",
    companyLogo: defaultValues?.meta?.companyLogo ?? "",
    companyName: defaultValues?.meta?.companyName ?? "",
    header: generateDefaultFormsHelper(
      Object.values(defaultValues?.header ?? {}),
      TITLE_CONTENT_DEFAULT_ENTRY
    ),
    main_images: generateDefaultFormsHelper(
      Object.values(defaultValues?.main?.images ?? {}).map((image) => ({
        image,
      })),
      IMAGE_DEFAULT_ENTRY
    ),
    main_paragraphs: generateDefaultFormsHelper(
      Object.values(main ?? {}),
      TITLE_CONTENT_DEFAULT_ENTRY
    ),
  };
}

const CompanyWalletEdit = ({ companyOptions, handleClose, defaultValues }) => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ id, formData }) =>
      adminApi.updateCompanyWalletById({ id, payload: formData }),

    onSuccess: () => {
      toast.success("Company Wallet Updated Successfully");
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.COMPANY_WALLET],
      });
    },
  });

  const submitHandler = async (value) => {
    try {
      const formData = new FormData();
      const changedIndices = [];
      const imagesUrls = [];
      value.main_images?.forEach((image, index) => {
        if (!isImageUrl(image.image)) {
          changedIndices.push(index);
          formData.append("images", image.image);
        } else imagesUrls.push(image.image);
      });
      formData.append(
        "payload",
        JSON.stringify({
          ...formatCompanyDataWalletHelper(value),
          changedIndices,
          newImageUrls: imagesUrls,
        })
      );

      await mutateAsync({
        id: defaultValues.id,
        formData,
      });
      handleClose();
    } catch (error) {
      showMutationError(error);
    }
  };
  return (
    <BaseCompanyWalletForm
      companyOptions={companyOptions}
      defaultValues={getDefaultValuesHelper({ companyOptions, defaultValues })}
      submitHandler={submitHandler}
      title={"Update"}
      isPending={isPending}
      dto={companyWalletDto.editCompanyWalletDto}
    />
  );
};

export default CompanyWalletEdit;
