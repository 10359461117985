import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import InvestmentPeriodSlider from "./InvestmentPeriodSlider";
import InvestmentPriceSlider from "./InvestmentPriceSlider";
import InvestmentPreferencesChips from "./InvestmentPreferencesChips";
import Divider from "@mui/material/Divider";
import BuyerAndSellerQuestionaires from "./BuyerAndSellerQuestionaries";
import { useUpdateCRM } from "hooks/useApi";
import { showMutationError } from "utils/common";
import UsersTypeEnum from "constants/UsersTypeEnum";

const BuyerAndSellerSurvey = ({ onSave }) => {
  const { user } = useAuth();

  const [errorMessage, setErrorMessage] = useState("");
  const [investmentPreferences, setInvestmentPreferences] = useState("");
  const [investmentPrice, setInvestmentPrice] = useState(100000);
  const [investmentPeriod, setInvestmentPeriod] = useState(2);

  const [companyName, setCompanyName] = useState("");
  const [amountOfSharesOptions, setAmountOfSharesOptions] = useState("");
  const [targetPrice, setTargetPrice] = useState("");
  const [sharesOrOptions, setSharesOrOptions] = useState("");
  const [isNextClicked, setIsNextClicked] = useState(false);
  const {mutateAsync:updateCRM,isPending} = useUpdateCRM()

  const handleNextClick = async (event) => {
    event.preventDefault();
    if (
      !investmentPreferences ||
      !investmentPrice ||
      !investmentPeriod ||
      !companyName ||
      !amountOfSharesOptions ||
      !targetPrice ||
      !sharesOrOptions
    ) {
      setErrorMessage("Please fill in all required fields.");
    } else {
      try {
        await updateCRM({
          investmentPreferences,
          investmentPrice:+investmentPrice,
          investmentPeriod:+investmentPeriod,
          companyName,
          amountOfSharesOptions:+amountOfSharesOptions,
          targetPrice:+targetPrice,
          sharesOrOptions,
          leadOrigin:`${UsersTypeEnum.BuyerAndSeller}_investmentDetailsForm`
        })
        setErrorMessage("");
        setIsNextClicked(true); 
      } catch (error) {
        showMutationError(error);
      }
    }
  };

  return (
    <Box>
      {!isNextClicked && (
        <Box
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>At Valoo, we believe in personalized investing.</div>
          <div className="separatorEmptyLarge"></div>
          {errorMessage && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
          <Box
            component="form"
            onSubmit={handleNextClick}
            noValidate
            sx={{ mt: 1 }}
          >
            {/* Buyer Survey Fields */}
            <Typography component="h1" variant="h5">
              Buyer Preferences
            </Typography>
            <div className="separatorEmptyLarge"></div>
            <InvestmentPreferencesChips
              selectedPreferences={investmentPreferences}
              onSelectPreference={setInvestmentPreferences}
              setErrorMessage={setErrorMessage}
            />
            <div className="separatorEmptyLarge"></div>
            <div className="separatorEmptyMedium"></div>
            <InvestmentPriceSlider
              value={investmentPrice}
              onChange={(e, newValue) => setInvestmentPrice(newValue)}
            />
            <div className="separatorEmptyLarge"></div>
            <div className="separatorEmptyMedium"></div>
            <Box sx={{ width: 300, margin: "auto", textAlign: "center" }}>
              <InvestmentPeriodSlider
                value={investmentPeriod}
                onChange={(e, newValue) => setInvestmentPeriod(newValue)}
              />
            </Box>
            <div className="separatorEmptyLarge"></div>
            <Divider />
            <div className="separatorEmptyLarge"></div>

            {/* Seller Survey Fields */}
            <Typography component="h1" variant="h5">
              Sale Details
            </Typography>
            {errorMessage && (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="companyName"
              label="Company I wish to sell"
              name="companyName"
              autoComplete="companyName"
              autoFocus
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
                setErrorMessage("");
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="amountOfSharesOptions"
              label="Amount of Shares/Options"
              name="amountOfSharesOptions"
              type="number"
              value={amountOfSharesOptions}
              onChange={(e) => {
                setAmountOfSharesOptions(e.target.value);
                setErrorMessage("");
              }}
              onError={(error) => setErrorMessage(error)}
            />
            <TextField
              margin="normal"
              fullWidth
              id="targetPrice"
              label="Target Price"
              name="targetPrice"
              type="text"
              value={
                targetPrice
                  ? `$${parseFloat(targetPrice).toLocaleString()}`
                  : ""
              }
              onChange={(e) => {
                const input = e.target.value;
                const formattedInput = input.replace(/[^\d]/g, "");
                setTargetPrice(formattedInput);
                setErrorMessage('')
              }}
              onError={(error) => setErrorMessage(error)}
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel id="sharesOrOptions-label">
                Shares or Options
              </InputLabel>
              <Select
                labelId="sharesOrOptions-label"
                id="sharesOrOptions"
                name="sharesOrOptions"
                label="Shares or Options"
                value={sharesOrOptions}
                onChange={(e) => {setSharesOrOptions(e.target.value);setErrorMessage('')}}
                defaultValue="" // Set the default value to an empty string
              >
                <MenuItem value="shares">Shares</MenuItem>
                <MenuItem value="options">Options</MenuItem>
                <MenuItem value="notSure">Not Sure</MenuItem>
              </Select>
            </FormControl>

            <div className="separatorEmptyLarge"></div>
            <Divider />
            <div className="separatorEmptyLarge"></div>
            <div>
              <strong>Not certain?</strong>
              <br />
              Provide your best estimate and reach out to a representative on
              the following page for personalized assistance.
            </div>
            <div className="separatorEmptySmall"></div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: "none" }}
              style={{ borderRadius: 20 }}
              color="orange"
              disabled={isPending}
            >
              {isPending ? "Loading..." : "Next"}
            </Button>
            <div className="separatorEmptyLarge"></div>
          </Box>
        </Box>
      )}
      {isNextClicked && (
        <Box sx={{ display: "grid", placeItems: "center" }}>
          <BuyerAndSellerQuestionaires
            buyerAndSellerSurveyData={{
              user,
              investmentPreferences,
              investmentPrice,
              investmentPeriod,
              onSave,
              companyName,
              amountOfSharesOptions,
              targetPrice,
              sharesOrOptions,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default BuyerAndSellerSurvey;
