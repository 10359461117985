import { useQuery } from "@tanstack/react-query";
import { adminApi } from "services/api";

export const useGetSingedUrl = (s3Key) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["storage", s3Key],
    queryFn: async () => adminApi.getSignedUrl(s3Key),
    refetchOnWindowFocus: false,
    enabled: !!s3Key,
    select: (data) => data?.data?.signedUrl,
  });

  return {
    data,
    isLoading,
    refetch,
  };
};
