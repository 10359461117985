import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  sendLeadToZapier,
  buildLeadData,
  leadTypeEnum,
} from "../../utils/zapier";
import { ReactComponent as Logo } from "../../images/valoo-logo.svg";
import styles from "./Questionnaire.module.css";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { apiInstance } from "../../services/api.service";

const AccreditedInvestorDeclaration = ({ buyerSurveryData }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [accreditedInvestor, setAccreditedInvestor] = useState({
    liquidAssetsOne: false,
    annualIncome: false,
    liquidAssetsTwo: false,
    corporationWithEquity: false,
    idNumber:""
  });

  const handleChange = (event) => {
    setAccreditedInvestor({
      ...accreditedInvestor,
      [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
    });
    setErrorMessage("");
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (
      !accreditedInvestor.liquidAssetsOne &&
      !accreditedInvestor.annualIncome &&
      !accreditedInvestor.liquidAssetsTwo &&
      !accreditedInvestor.corporationWithEquity
    ) {
      setErrorMessage("Please select at least one option.");
      return;
    } else {
      setErrorMessage("");
    }

    if(!accreditedInvestor.idNumber?.length > 3){
      setErrorMessage("Please enter your ID number.");
      return;
    }
    // const data = new FormData(event.currentTarget);
    try {
      const response = await apiInstance.post("/api/survey/buyer-survey",{
        userId: buyerSurveryData.user.id,
        investmentPreferences: buyerSurveryData.investmentPreferences,
        investmentPrice: buyerSurveryData.investmentPrice,
        investmentPeriod: `${buyerSurveryData.investmentPeriod}`,
        accreditedInvestor: Object?.values(accreditedInvestor)?.some(Boolean),
        // checkedAccreditedInvesterOptions : Object?.keys(accreditedInvestor)
        liquidAssetsOne: accreditedInvestor["liquidAssetsOne"],
        annualIncome: accreditedInvestor["annualIncome"],
        liquidAssetsTwo: accreditedInvestor["liquidAssetsTwo"],
        corporationWithEquity: accreditedInvestor["corporationWithEquity"],
        idNumber: accreditedInvestor["idNumber"],
        // Add other buyer survey fields
      });

      if (response.status >= 200 && response.status < 300) {
        const leadContent = {
          userId: buyerSurveryData.user.id,
          investmentPreferences: buyerSurveryData.investmentPreferences,
          investmentPrice: buyerSurveryData.investmentPrice,
          investmentPeriod: `${buyerSurveryData.investmentPeriod}`,
          // accreditedInvestor: data.get("accreditedInvestor"),
          accreditedInvestor: Object?.values(accreditedInvestor)?.some(Boolean),
          // checkedAccreditedInvesterOptions : Object?.keys(accreditedInvestor),
          liquidAssetsOne: accreditedInvestor["liquidAssetsOne"],
          annualIncome: accreditedInvestor["annualIncome"],
          liquidAssetsTwo: accreditedInvestor["liquidAssetsTwo"],
          corporationWithEquity: accreditedInvestor["corporationWithEquity"],
          phone: buyerSurveryData.user?.phone || "",
        };
        const leadData = buildLeadData(leadTypeEnum.SurveyBuyer, leadContent);
        await sendLeadToZapier(leadData);

        buyerSurveryData.onSave(); // Notify the parent component about the save
      } else {
        setErrorMessage("An error occurred while saving the survey");
      }
    } catch (error) {
      console.error("Error during survey save:", error);
      setErrorMessage("An error occurred while saving the survey");
    }
  };

  return (
    <Box>
      <Box className={styles.mainContainer}>
        <Box className={styles.headerContainer}>
          <Typography
            component="h1"
            variant="h5"
            style={{ color: "white", fontSize: "18px" }}
            className="declaration-heading"
          >
            Accredited Investor Declaration
          </Typography>
        </Box>
      </Box>
      <Box className={styles.contentContainer}>
        <div className="separatorEmptyLarge"></div>
        {errorMessage && (
          <Typography
            variant="body2"
            color="error"
            sx={{ textAlign: "center" }}
          >
            {errorMessage}
          </Typography>
        )}
        <Box>
          <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 3 }}>
            <Typography
              component="h6"
              variant="h6"
              style={{ textAlign: "justify" }}
            >
              I the undersigned,
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              sx={{ mt: 3, textAlign: "justify" }}
            >
              Hereby affirm that I am an accredited investor and agree to the
              following:
            </Typography>
            <Typography>(Select all that apply)</Typography>
            <Box sx={{ ml: 4, mb: 9 }} className="accredited-form-check">
              <FormControlLabel
                control={
                  <Checkbox
                    name="liquidAssetsOne"
                    checked={accreditedInvestor.liquidAssetsOne}
                    onChange={handleChange}
                  />
                }
                sx={{ mt: 3 }}
                label="The total value of my liquid assets: cash, deposits, financial assets, and securities-worth exceeds 9 million NIS (as adjusted according to Israel's Consumer Price Index)."
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="annualIncome"
                    checked={accreditedInvestor.annualIncome}
                    onChange={handleChange}
                  />
                }
                sx={{ mt: 3 }}
                label="My annual income in each of the last two years exceeded 1.4 million NIS (as adjusted according to Israel's Consumer Price Index), or the income of my family unit (myself and my household members or others who are financially dependent on me) exceeded 2.1 million NIS (as adjusted according to Israel's Consumer Price Index)."
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="liquidAssetsTwo"
                    checked={accreditedInvestor.liquidAssetsTwo}
                    onChange={handleChange}
                  />
                }
                sx={{ mt: 3 }}
                label="The total value of my liquid assets exceeds 5.8 million NIS (as adjusted according to Israel's Consumer Price Index), and my annual income in each of the last two years exceeded 700 thousand NIS (as adjusted according to Israel's Consumer Price Index) or the income of my family unit in each of the last two years exceeded 1 million NIS (as adjusted according to Israel's Consumer Price Index)."
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="corporationWithEquity"
                    checked={accreditedInvestor.corporationWithEquity}
                    onChange={handleChange}
                  />
                }
                sx={{ mt: 3 }}
                label="I represent a corporation whose equity is valued at more NIS 50 million NIS and was not incorporated in order to purchase securities."
              />
              <TextField
               sx={{ mt: 3 }}
                variant="outlined"
                label="ID Number"
                name="idNumber"
                value={accreditedInvestor.idNumber}
                onChange={handleChange}
                required
                fullWidth
                onBlur={(e) => e.target.classList.remove("invalidInput")}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 5, mb: 2, textTransform: "none", height: "43px" }}
                style={{ borderRadius: 20 }}
                color="orange"
                className="submit-btn"
              >
                I declare that I am an accredited investor
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={styles.footerContainer}>
          <Box>
            <Typography
              variant="subtitle1"
              style={{
                color: "#979797",
                fontSize: "16px",
                fontWeight: 400,
                textAlign: "justify",
              }}
            >
              *Liquid assets - This refers to cash, deposits, financial assets,
              and securities as defined by the Regulation of Investment Advice,
              Investment Marketing, and Investment Portfolio Management Law of
              1995.
              <br />
              Financial Assets - Instruments as defined by the Joint Investment
              Trust Law of 1994, stocks or shares in Funds registered outside of
              Israel, options, futures, structured or indexed products and
              pension funds.
            </Typography>

            <Typography
              style={{
                color: "#979797",
                fontSize: "16px",
                marginTop: "15px",
                fontWeight: 400,
                textAlign: "justify",
              }}
            >
              **Family Unit - Defined as an individual and his/her household
              living with him/her and/or dependents.
            </Typography>
          </Box>
          <Box sx={{ mt: 3, mb: 3 }}>
            <Logo alt="Valoo Logo" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccreditedInvestorDeclaration;
