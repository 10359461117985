import { RestartAltRounded } from "@mui/icons-material";
import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { PAGINATION_OPTIONS } from "constants/common";

const ZenDataGridComponent = styled(DataGrid)(({ theme }) => ({
  margin: 2,
  "& .MuiDataGrid-cell": {
    textTransform: "capitalize",
  },

  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
    {
      outline: "none !important",
    },
}));

const ZenDataGrid = ({
  data,
  isLoading,
  paginationModel,
  setPaginationModel,
  sortModel,
  setSortModel,
  columns,
  ...rest
}) => (
  <ZenDataGridComponent
    getRowHeight={() => 60}
    disableColumnResize
    disableRowSelectionOnClick
    disableColumnFilter
    disableColumnSelector
    disableDensitySelector
    disableColumnMenu
    rowCount={data.meta?.totalCount}
    pagination
    loading={isLoading}
    paginationMode="server"
    initialState={{
      pagination: {
        paginationModel,
      },
      sorting: {
        sortModel,
      },
    }}
    sortingMode="server"
    onSortModelChange={(newSortModel) => {
      setSortModel(newSortModel);
    }}
    pageSizeOptions={PAGINATION_OPTIONS}
    onPaginationModelChange={setPaginationModel}
    columns={columns}
    rows={data.data}
    {...rest}
  />
);

export default ZenDataGrid;
