import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import {
  sendLeadToZapier,
  buildLeadData,
  leadTypeEnum,
} from "../../utils/zapier";
import { apiInstance } from "../../services/api.service";

const SellerSurvey = ({ onSave }) => {
  const { user } = useAuth();

  const [sharesOrOptions, setSharesOrOptions] = useState("");
  const [targetPrice, setTargetPrice] = useState("");
  const [amountOfSharesOptions, setAmountOfSharesOptions] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isPending, setIsPending] = useState(false);

  const handleSave = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const form = event.currentTarget;
    const companyName =  data.get("companyName");
    const amountOfSharesOptions = data.get("amountOfSharesOptions");
    const sharesOrOptions =  data.get("sharesOrOptions");

    if (!form.checkValidity()) {
      // Mark the invalid fields with the "invalidInput" class
      form.reportValidity();
      setErrorMessage("Please fill in all required fields.");
      return; // Prevent form submission
    } else {
      setErrorMessage("");
    }

    try {
      setIsPending(true);
      const response = await apiInstance.post("/api/survey/seller-survey",{
        userId: user.id,
        companyName,
        sharesOrOptions,
        targetPrice: targetPrice,
        amountOfSharesOptions,
        // Add other seller survey fields
      });

      if (response.status >= 200 && response.status < 300) {
        const leadContent = {
          userId: user.id,
          companyName,
          targetPrice: targetPrice,
          sharesOrOptions,
          amountOfSharesOptions,
          phone: user?.phone || "",
        };
        const leadData = buildLeadData(leadTypeEnum.SurveySeller, leadContent);
        await sendLeadToZapier(leadData);

        // Save success
        onSave(); // Notify the parent component about the save
      } else {
        // Server error
        setErrorMessage("An error occurred while saving the survey");
      }
    } catch (error) {
      console.error("Error during survey save:", error);
      setErrorMessage("An error occurred while saving the survey");
    }finally{
      setIsPending(false);
    }
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5">
        Sale Details
      </Typography>
      <div className="separatorEmptyLarge"></div>
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="companyName"
          label="Company I wish to sell"
          name="companyName"
          autoComplete="companyName"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="amountOfSharesOptions"
          label="Amount of Shares/Options"
          name="amountOfSharesOptions"
          type="number"
          value={amountOfSharesOptions}
          onChange={(e) => setAmountOfSharesOptions(e.target.value)}
          // Use onError to manage error state
          onError={(error) => setErrorMessage(error)}
        />
        <TextField
          margin="normal"
          fullWidth
          id="targetPrice"
          label="Target Price"
          name="targetPrice"
          type="text"
          value={
            targetPrice ? `$${parseFloat(targetPrice).toLocaleString()}` : ""
          }
          onChange={(e) => {
            const input = e.target.value;
            const formattedInput = input.replace(/[^\d]/g, "");
            setTargetPrice(formattedInput);
          }}
          onError={(error) => setErrorMessage(error)}
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="sharesOrOptions-label">Shares or Options</InputLabel>
          <Select
            labelId="sharesOrOptions-label"
            id="sharesOrOptions"
            name="sharesOrOptions"
            label="Shares or Options"
            value={sharesOrOptions}
            onChange={(e) => setSharesOrOptions(e.target.value)}
            defaultValue="" // Set the default value to an empty string
          >
            <MenuItem value="shares">Shares</MenuItem>
            <MenuItem value="options">Options</MenuItem>
            <MenuItem value="notSure">Not Sure</MenuItem>
          </Select>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, textTransform: "none" }}
          style={{ borderRadius: 20 }}
          color="orange"
          disabled={isPending}
        >
          {isPending ? "Loading..." : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default SellerSurvey;
