import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Divider from "@mui/material/Divider";
import { crmFieldsInfoLookup } from "constants/info";
import Chip from "@mui/material/Chip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[4],
    fontSize: 11,
    minWidth: 400,
    border: "1px solid #011e37",
  },
}));

function InfoTooltip({ crmKey, children }) {
  const info = crmFieldsInfoLookup[crmKey] ?? {};
  return (
    <HtmlTooltip
      title={
        <Stack p={1} direction={"column"} gap={1}>
          <Stack direction="row" alignItems="center" gap={1}>
            <InfoOutlinedIcon />
            <Typography fontWeight={600} color="inherit">
              {info.formLabel}
            </Typography>
          </Stack>
          <Typography
            sx={{ color: "text.secondary", textTransform: "capitalize" }}
            variant="body2"
            color="inherit"
          >
            {info.description}
          </Typography>
          <Typography variant="caption">{info.valueDescription}</Typography>
          <Divider my={0} textAlign="right">
            <Chip
              variant="outlined"
              color="secondary"
              label={crmKey}
              size="small"
            />
          </Divider>
        </Stack>
      }
      arrow
    >
      {children}
    </HtmlTooltip>
  );
}

function HeaderRenderer(params) {
  return (
    <InfoTooltip crmKey={params?.colDef?.crmKey}>
      <span>{params?.colDef?.headerName}</span>
    </InfoTooltip>
  );
}
export default HeaderRenderer;