import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, DialogContentText } from "@mui/material";

export default function BaseModal({
  open,
  handleClick,
  loading,
  handleClose,
  title,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          // Prevent closing on backdrop click or pressing Escape
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            handleClose();
          }
        }}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will soft delete the selected item, You can't undo this.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Disagree
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={loading}
            onClick={handleClick}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
