import * as yup from "yup";

const baseInputs = yup.object().shape({
  author: yup.string().required("Author is required."),
  title: yup.string().required("Title is required."),
  content: yup.string().required("Content is required."),
  company_id: yup.number().required("Company is required."),
});

export const createShareHolder = yup
  .object()
  .shape({
    featured_image: yup.mixed().required("Please upload an image."),
    file_name: yup.mixed().required("Please upload File"),
  })
  .concat(baseInputs);

export const editShareHolder = yup
  .object()
  .shape({
    id: yup.string().required(),
    featured_image: yup.mixed().nullable(),
    file_name: yup.mixed().nullable(),
  })
  .concat(baseInputs);
