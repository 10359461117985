import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { DialogTitle, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BaseDrawer = ({ open, toggleDrawer, title, children, width = 500 }) => {
  return (
    <Drawer anchor="right" open={open}>
      <Box sx={{ width, maxWidth: "90vw", p: 2 }} role="presentation">
        <Stack
          alignItems={"center"}
          direction="row"
          justifyContent="space-between"
        >
          <DialogTitle>{title}</DialogTitle>
          <IconButton onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
        </Stack>
        {children}
      </Box>
    </Drawer>
  );
};

export default BaseDrawer;
