import { Box, Chip } from "@mui/material";
import React from "react";

const BooleanChip = ({ value }) => {
  return (
    <Box>
      <Chip
        label={value ? "Yes" : "No"}
        color={value ? "success" : "error"}
        variant="outlined"
      />
    </Box>
  );
};

export default BooleanChip;
