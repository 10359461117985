import { useMutation } from "@tanstack/react-query";
import BaseCompanyStocksForm from "components/admin/company-stocks/BaseForm";
import queryClient from "config/queryClient";
import {
  CACHE_KEYS,
  IMAGE_DEFAULT_ENTRY,
  TITLE_CONTENT_DEFAULT_ENTRY,
  TITLE_IMAGE_CONTENT_DEFAULT_ENTRY,
} from "constants/common";
import { companyStocksDto } from "dto";
import toast from "react-hot-toast";
import { adminApi } from "services/api";
import {
  formatCompanyStocksDataHelper,
  formatCompanyStocksImagesHelper,
  generateDefaultFormsHelper,
  showMutationError,
} from "utils/common";

function getDefaultValuesHelper({ companyOptions }) {
  return {
    company_id: companyOptions?.length ? companyOptions[0].id : "",
    meta: {
      website: "",
      category: "",
      companyName: "",
    },
    price: {
      max: 0,
      min: 0,
      defaultValue: 0,
    },
    range: {
      max: 0,
      min: 0,
      defaultValue: 0,
    },
    header: generateDefaultFormsHelper([], TITLE_CONTENT_DEFAULT_ENTRY),
    taba_title: "",
    taba_images: generateDefaultFormsHelper([], IMAGE_DEFAULT_ENTRY),
    taba_content: generateDefaultFormsHelper([], TITLE_CONTENT_DEFAULT_ENTRY),
    tabb_title: "",
    tabb_content: generateDefaultFormsHelper(
      [],
      TITLE_IMAGE_CONTENT_DEFAULT_ENTRY
    ),
    tabc_title: "",
    tabc_images: generateDefaultFormsHelper([], IMAGE_DEFAULT_ENTRY),
    tabc_content: generateDefaultFormsHelper([], TITLE_CONTENT_DEFAULT_ENTRY),
    tabd_title: "",
    tabd_content: generateDefaultFormsHelper([], TITLE_CONTENT_DEFAULT_ENTRY),
  };
}

const CompanyStocksAdd = ({ companyOptions, handleClose }) => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: adminApi.insertCompanyStock,
    onSuccess: () => {
      toast.success("Company Stocks Created Successfully");
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.STOCKS],
      });
    },
  });
  const submitHandler = async (value) => {
    try {
      const formData = new FormData();
      const formattedJson = formatCompanyStocksDataHelper(value);
      formData.append("payload", JSON.stringify(formattedJson));
      formatCompanyStocksImagesHelper({ payload: value, formData });
      await mutateAsync(formData);
      handleClose();
    } catch (error) {
      showMutationError(error);
    }
  };
  return (
    <BaseCompanyStocksForm
      companyOptions={companyOptions}
      handleClose={handleClose}
      submitHandler={submitHandler}
      title={"Create"}
      isPending={isPending}
      defaultValues={getDefaultValuesHelper({ companyOptions })}
      dto={companyStocksDto.createCompanyStocksDto}
    />
  );
};

export default CompanyStocksAdd;
