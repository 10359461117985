import React from "react";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { Box } from "@mui/material";

const NotFoundPage = () => {
  return (
    <Box
      sx={{ height: "100%" }}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        flexDirection={"column"}
        columnGap={2}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <GppMaybeIcon sx={{ fontSize: "150px" }} color="primary" />
        <h2>Page Not Found</h2>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
