import { useMutation } from "@tanstack/react-query";
import BaseCompanyWalletForm from "components/admin/company-wallet/BaseForm";
import queryClient from "config/queryClient";
import {
  CACHE_KEYS,
  IMAGE_DEFAULT_ENTRY,
  TITLE_CONTENT_DEFAULT_ENTRY,
} from "constants/common";
import { companyWalletDto } from "dto";
import toast from "react-hot-toast";
import { adminApi } from "services/api";
import {
  formatCompanyDataWalletHelper,
  generateDefaultFormsHelper,
  showMutationError,
} from "utils/common";

function getDefaultValuesHelper({ companyOptions }) {
  return {
    company_id: companyOptions?.length ? companyOptions[0].id : "",
    category: "",
    companyLogo: "",
    companyName: "",
    header: generateDefaultFormsHelper([], TITLE_CONTENT_DEFAULT_ENTRY),
    main_images: generateDefaultFormsHelper([], IMAGE_DEFAULT_ENTRY),
    main_paragraphs: generateDefaultFormsHelper(
      [],
      TITLE_CONTENT_DEFAULT_ENTRY
    ),
  };
}

const CompanyWalletAdd = ({ companyOptions, handleClose }) => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: adminApi.insertCompanyWallet,
    onSuccess: () => {
      toast.success("Company Wallet Created Successfully");
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.COMPANY_WALLET],
      });
    },
  });

  const submitHandler = async (value) => {
    try {
      const formData = new FormData();
      value.main_images?.forEach((image) => {
        formData.append("images", image.image);
      });
      formData.append(
        "payload",
        JSON.stringify(formatCompanyDataWalletHelper(value))
      );
      await mutateAsync(formData);
      handleClose();
    } catch (error) {
      // console.trace(error);
      showMutationError(error);
    }
  };
  return (
    <BaseCompanyWalletForm
      companyOptions={companyOptions}
      defaultValues={getDefaultValuesHelper({ companyOptions })}
      submitHandler={submitHandler}
      title="Create"
      isPending={isPending}
      dto={companyWalletDto.createCompanyWalletDto}
    />
  );
};

export default CompanyWalletAdd;
