import { Delete, Edit } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import withPaginatedQuery from "components/HOC/withPaginatedQuery";
import { BaseDrawer, BaseModal } from "components/UI/Base";
import {
  CACHE_KEYS,
  COMPANY_THUMBNAIL_COLUMN,
  MODAL_TYPE,
  VIEW_MODAL_INITIAL_STATE,
} from "constants/common";
import { useCompanyOptions } from "hooks/useApi";
import { useModal } from "hooks/useModal";
import { useMemo, useState } from "react";
import { adminApi } from "services/api";
import CompanyWalletAdd from "./CompanyWalletAdd";
import CompanyWalletEdit from "./CompanyWalletEdit";

import { useMutation } from "@tanstack/react-query";
import ZenDataGrid from "components/UI/ZenGrid";
import queryClient from "config/queryClient";
import toast from "react-hot-toast";
import { showMutationError } from "utils/common";
import ViewDetails from "./ViewDetails";

const ManageCompanyDataWallet = ({
  paginationModel,
  setPaginationModel,
  data,
  isLoading,
  sortModel,
  handleSearch,
  search,
  setSortModel,
}) => {
  const { modalState, modalStateHandler, setModalState } = useModal(
    VIEW_MODAL_INITIAL_STATE
  );
  const { data: companyOptions } = useCompanyOptions();
  const [selectedRow, setSelectedRow] = useState(null);

  const { isPending, mutateAsync } = useMutation({
    mutationFn: () => adminApi.deleteCompanyWalletById({ id: selectedRow.id }),
    onSuccess: () => {
      toast.success("Wallet Record deleted successfully");
      queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.COMPANY_WALLET] });
    },
  });

  const deleteHandler = async () => {
    try {
      await mutateAsync();
      setModalState(VIEW_MODAL_INITIAL_STATE);
    } catch (error) {
      showMutationError(error);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        flex: 0.2,
        field: "id",
        headerName: "#",
        headerAlign: "center",
        align: "center",
      },
      ...COMPANY_THUMBNAIL_COLUMN,
      {
        field: "actions",
        type: "actions",
        sortable: false,
        flex: 0.7,
        headerName: "Actions",
        getActions(params) {
          return [
            <IconButton
              key="view-btn"
              onClick={() => {
                setSelectedRow(params.row);
                modalStateHandler(MODAL_TYPE.VIEW, true);
              }}
            >
              <VisibilityIcon />
            </IconButton>,
            <IconButton
              key="edit-btn"
              onClick={() => {
                setSelectedRow(params.row);
                modalStateHandler(MODAL_TYPE.EDIT, true);
              }}
            >
              <Edit />
            </IconButton>,
            <IconButton
              key="delete-btn"
              onClick={() => {
                setSelectedRow(params.row);
                modalStateHandler(MODAL_TYPE.DELETE, true);
              }}
            >
              <Delete />
            </IconButton>,
          ];
        },
      },
    ];
  }, []);

  let content = <></>;
  if (isLoading) {
    content = (
      <Box my={4} textAlign="center">
        <CircularProgress />;
      </Box>
    );
  }

  if (data) {
    content = (
      <ZenDataGrid
        data={data}
        columns={columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sortModel={sortModel}
        setSortModel={setSortModel}
      />
    );
  }

  return (
    <>
      <Card elevation={3} sx={{ maxWidth: "lg", borderRadius: 2 }}>
        <CardHeader
          title="Company Wallet Details"
          action={
            <Stack direction="row" spacing={2}>
              <TextField
                value={search}
                onChange={handleSearch}
                sx={{ maxWidth: 200 }}
                size="small"
                variant="outlined"
                placeholder="Search"
              />
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  modalStateHandler(MODAL_TYPE.CREATE, true);
                }}
              >
                Add Wallet Record
              </Button>
            </Stack>
          }
        />
        <Box sx={{ height: 600 }}>{content}</Box>
      </Card>
      <>
        <BaseDrawer
          open={modalState[MODAL_TYPE.CREATE]}
          toggleDrawer={() => modalStateHandler(MODAL_TYPE.CREATE, false)}
          title="Add Company Data Wallet"
        >
          <CompanyWalletAdd
            handleClose={() => modalStateHandler(MODAL_TYPE.CREATE, false)}
            companyOptions={companyOptions}
          />
        </BaseDrawer>
        {selectedRow ? (
          <>
            <BaseDrawer
              width={1000}
              title={"View Wallet Details"}
              open={modalState[MODAL_TYPE.VIEW]}
              toggleDrawer={() => modalStateHandler(MODAL_TYPE.VIEW, false)}
            >
              <ViewDetails companyWalletDetails={selectedRow} />
            </BaseDrawer>
            <BaseDrawer
              title={"Edit Wallet Buy"}
              open={modalState[MODAL_TYPE.EDIT]}
              toggleDrawer={() => modalStateHandler(MODAL_TYPE.EDIT, false)}
            >
              <CompanyWalletEdit
                key={selectedRow.id}
                handleClose={() => modalStateHandler(MODAL_TYPE.EDIT, false)}
                defaultValues={selectedRow}
                companyOptions={companyOptions}
              />
            </BaseDrawer>
            <BaseModal
              handleClick={deleteHandler}
              open={modalState[MODAL_TYPE.DELETE]}
              handleClose={() => modalStateHandler(MODAL_TYPE.DELETE, false)}
              loading={isPending}
              title="Are you sure you want to delete this Company Wallet?"
            />
          </>
        ) : null}
      </>
    </>
  );
};

export default withPaginatedQuery(ManageCompanyDataWallet, {
  queryKey: [CACHE_KEYS.COMPANY_WALLET],
  queryFn: adminApi.getAllCompanyWalletData,
});
