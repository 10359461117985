import AdminDashboardLayout from "components/layout/dashboard";
import ManageCompanies from "pages/admin/Company";
import Home from "pages/admin/Home";
import ManageNewsLetters from "pages/admin/news-letter";
import NotFoundPage from "pages/admin/NotFound";
import ManageShareHolders from "pages/admin/shareholders";
import ManageStayUpdated from "pages/admin/stay-updated";
import ManageUserWalletSell from "pages/admin/wallet-sell";
import ManageUserWalletBuy from "pages/admin/wallet-buy";
import ManageCompanyNDA from "pages/admin/nda";
import ManageSurveys from "pages/admin/survey";
import React from "react";
import { Route, Routes } from "react-router-dom";
import ManageCompanyDataWallet from "pages/admin/company-wallet";
import ManageCompanyStocks from "pages/admin/company-stocks";
import ManageUsers from "pages/admin/Users";
import ErrorBoundary from "components/HOC/ErrorBoundary";
import AdminInfo from "pages/admin/info/AdminInfo";

const AdminNavigation = () => {
  //   const { user } = useAuth();
  //   console.log("user", user);
  //   if (!user?.role === "admin") return <></>;
  return (
    <Routes>
      <Route
        element={
          <ErrorBoundary>
            <AdminDashboardLayout />
          </ErrorBoundary>
        }
      >
        <Route index element={<Home />} />
        <Route path="manage-newsletters" element={<ManageNewsLetters />} />
        <Route path="manage-users" element={<ManageUsers />} />
        <Route path="manage-companies" element={<ManageCompanies />} />
        <Route path="manage-stay-updated" element={<ManageStayUpdated />} />
        <Route path="manage-shareholders" element={<ManageShareHolders />} />
        <Route path="wallet/sell" element={<ManageUserWalletSell />} />
        <Route path="wallet/buy" element={<ManageUserWalletBuy />} />
        <Route path="user-company-nda" element={<ManageCompanyNDA />} />
        <Route path="registration-survey" element={<ManageSurveys />} />
        <Route
          path="manage-company-wallet"
          element={<ManageCompanyDataWallet />}
        />
        <Route path="manage-company-stocks" element={<ManageCompanyStocks />} />
        <Route path="info" element={<AdminInfo />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AdminNavigation;
