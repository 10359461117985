import { Box, Chip, Divider } from "@mui/material";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const ObjectView = ({ title, data }) => {
  return (
    <Box sx={{ my: 1, maxWidth: "sm" }}>
      <Divider sx={{ mb: 2 }} textAlign="left">
        <Chip label={title} color="primary" size="large" />
      </Divider>
      <Table aria-label="simple table">
        <TableBody>
          {Object.keys(data).map((key) => (
            <TableRow
              key={key}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                component="th"
                scope="row"
              >
                {key}
              </TableCell>
              <TableCell align="right">{data[key]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ObjectView;
