import DetailedContentView from "components/admin/details/DetailedContentView";
import ImageGallery from "components/admin/details/ImageGallery";
import ObjectView from "components/admin/details/ObjectView";
import DividerChip from "components/admin/UI/DividerChip";
import { useMemo } from "react";

function transformHelper(data) {
  return Object.keys(data).reduce((prev, accumulator) => {
    const value = data?.[accumulator];
    if (value && typeof value === "object" && "title" in value) {
      prev.push(value);
    }
    return prev;
  }, []);
}

const ViewDetails = ({ companyWalletDetails }) => {
  const { imageUrls, headerContent, mainContent } = useMemo(() => {
    const imageUrls = Object.values(companyWalletDetails?.main?.images || {});
    const headerContent = transformHelper(companyWalletDetails?.header || {});
    const mainContent = transformHelper(companyWalletDetails?.main || {});
    return {
      imageUrls,
      headerContent,
      mainContent,
    };
  }, [companyWalletDetails]);
  return (
    <>
      <ObjectView title="Meta" data={companyWalletDetails?.meta || {}} />
      <DividerChip>Header</DividerChip>
      <DetailedContentView data={headerContent} />
      <ImageGallery imageUrls={imageUrls} />
      <DividerChip>Main</DividerChip>
      <DetailedContentView data={mainContent} />
    </>
  );
};

export default ViewDetails;
