import * as yup from "yup";
import * as common from "dto/common";

const metaSchema = yup
  .object()
  .shape({
    website: yup.string().required().url("Link must be a valid URL"),
    category: yup.string().required("Category is required."),
    companyName: yup.string().required("Company Name is required."),
  })
  .required();

const priceSchema = yup
  .object()
  .shape({
    max: yup
      .number()
      .required("Max is required.")
      .moreThan(0, "Max must be greater than 0"),
    min: yup
      .number()
      .required("Min is required.")
      .moreThan(0, "Min must be greater than 0"),
    defaultValue: yup
      .number()
      .required("Default value is required.")
      .moreThan(0, "Default value must be greater than 0"),
  })
  .required();

const contentArraySchema = yup
  .array()
  .of(common.titleContentFormSchema.clone().required())
  .min(1)
  .max(10);

const companyStocksInputs = yup.object().shape({
  company_id: yup.number().required("Company is required."),
  meta: metaSchema.required(),
  price: priceSchema.clone().required(),
  range: priceSchema.clone().required(),
  header: contentArraySchema.required("Header is required."),
  taba_title: yup.string().required("TabA title is required."),
  taba_images: yup
    .array()
    .of(common.imageFormSchema)
    .required("TabA images is required.")
    .min(1)
    .max(10),
  taba_content: contentArraySchema.required("TabA content is required."),
  tabb_title: yup.string().required("TabB title is required."),
  tabb_content: yup
    .array()
    .of(
      common.titleContentFormSchema
        .clone()
        .shape({
          image: yup.mixed().required("Please upload an image."),
        })
        .required("TabB content is required.")
    )
    .required("TabB content is required.")
    .min(1)
    .max(10),
  tabc_title: yup.string().required("TabC title is required."),
  tabc_images: yup
    .array()
    .of(common.imageFormSchema.clone().required())
    .required("TabC images is required.")
    .min(1)
    .max(10),
  tabc_content: contentArraySchema.required("TabC content is required."),
  tabd_title: yup.string().required("TabD title is required."),
  tabd_content: contentArraySchema.required("TabD content is required."),
});

export const createCompanyStocksDto = companyStocksInputs;

export const editCompanyStocksDto = companyStocksInputs.clone().shape({
  id: yup.number().required("ID is required."),
});
